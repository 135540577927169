import React from "react";
import { Grid, Divider, Typography } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import "../../CustomersTab/CustomerPage.css";
import "../../EditBills/editbills.css";

// ============================================|| EDIT BILLS LHS LIST HEADER ||============================================ //

export const EditBillsLhsListHeader = ({ filteredCustomersData }) => {
  const {
    labels: { customerNameText, pendingBillsText },
  } = uiTexts;

  const GridHeaderItem = ({ xs, text }) => (
    <Grid item xs={xs}>
      <Typography className="customer-list-header-text">{text}</Typography>
    </Grid>
  );

  //Calculate count of pendingbill
  const totalBillNumbers = filteredCustomersData?.reduce((total, party) => {
    return total + party.billNumbers.length;
  }, 0);

  return (
    <>
      <Grid container className="customer-list-header">
        <GridHeaderItem
          xs={9.5}
          text={`${customerNameText} (${filteredCustomersData?.length || 0})`}
        />

        <GridHeaderItem
          xs={2.5}
          text={`${pendingBillsText} (${totalBillNumbers || 0})`}
        />
      </Grid>

      <Divider className="customer-list-header-divider" />
    </>
  );
};
