import React, { useState } from "react";
import { Dialog, Divider } from "@mui/material";
import { StockUpdateModalHeader } from "../StockUpdateModal/StockUpdateModalHeader";
import { formatDate, getFormattedDate } from "../../Utils/common";
import { useDispatch } from "react-redux";
import { StockUpdateModalActionButton } from "../StockUpdateModal/StockUpdateModalActionButton";
import { uiTexts } from "../../Utils/uiTexts";
import { StockModalTextfield } from "../StockUpdateModal/StockModalTextfield";
import {
  editStock,
  getStockDetailsByCategory,
} from "../../Services/StockService";
import { CategoryWiseStockData } from "../../Store/Reducers/data_menu";

// ===========================================|| STOCK EDIT MODAL ||=========================================== //

export const StockEditModal = ({
  isOpenEditStockModal,
  handleCloseEditStockModal,
  editedItemData,
  setUpdatedItemData,
  updatedItemData,
  selectedCategory,
  filteredDate,
}) => {
  const {
    labels: {
      dateText,
      itemNameText,
      brandNameText,
      cleanCategory,
      unitText,
      unKnownText,
    },
    warnings: { onlyNumbersAreAllowed },
    keyLabels: { finishedText, processingText },
    placeHolderTexts: {
      finishedKgText,
      finishedBagsText,
      processsingKgText,
      processingBagsText,
      dateInputFormat,
    },
  } = uiTexts;

  const dispatch = useDispatch();

  const [isDisabled, setIsDisabled] = useState(false);
  const [editStockQuantity, setEditStockQuantity] = useState(
    editedItemData?.bags || editedItemData?.quantityInKg || 0,
  );
  const [editProcessingQuantity, setEditProcessingQuantity] = useState(
    editedItemData?.processingBags ||
      editedItemData?.processingQuantityInKg ||
      0,
  );
  const [editQuantityError, setEditQuantityError] = useState({
    finished: false,
    processing: false,
  });

  const handleChangeStockQuantity = (setter, label, e) => {
    const value = e.target.value;
    setter(value);

    if (!/^\d*$/.test(value)) {
      setEditQuantityError((prevState) => ({
        ...prevState,
        [label]: true,
      }));
    } else {
      setEditQuantityError((prevState) => ({
        ...prevState,
        [label]: false,
      }));
    }
  };

  const handleEditItem = async () => {
    setIsDisabled(true);
    const quantity = Number(editStockQuantity);
    const processing = Number(editProcessingQuantity);

    const updateData = editedItemData?.bags
      ? { bags: quantity, processingBags: processing }
      : {
          quantityInKg: quantity,
          processingQuantityInKg: processing,
        };

    await editStock(editedItemData?.id, updateData).then(async ({ status }) => {
      if (status === 200) {
        const { data } = await getStockDetailsByCategory(selectedCategory?.id);
        dispatch(CategoryWiseStockData(data));

        if (!filteredDate) return;

        const formattedFilteredDate = getFormattedDate(
          filteredDate,
          dateInputFormat,
        );

        const filteredStock = data?.filter(
          (item) =>
            getFormattedDate(item.date, dateInputFormat) ===
            formattedFilteredDate,
        );

        setUpdatedItemData(filteredStock);
      }
    });

    handleCloseEditStockModal();
  };

  const isEditDisabled = () => {
    return (
      editStockQuantity === "" ||
      editProcessingQuantity === "" ||
      editQuantityError?.finished ||
      editQuantityError?.processing ||
      isDisabled
    );
  };

  const isButtonDisabled = isEditDisabled();

  return (
    <Dialog
      open={isOpenEditStockModal}
      PaperProps={{
        sx: {
          borderRadius: "20px",
        },
      }}
    >
      <StockUpdateModalHeader
        title={editedItemData?.itemName}
        handleClose={handleCloseEditStockModal}
      />

      <Divider />

      <div className="list-container stock-update-modal-height">
        <StockModalTextfield
          typographyText={dateText}
          value={formatDate(editedItemData?.date)}
          disabled={true}
        />

        <StockModalTextfield
          typographyText={itemNameText}
          value={editedItemData?.itemName}
          disabled={true}
        />

        {editedItemData?.brandName !== unKnownText && (
          <StockModalTextfield
            typographyText={brandNameText}
            value={editedItemData?.brandName}
            disabled={true}
          />
        )}

        {editedItemData?.cleanCategoryName !== unKnownText && (
          <StockModalTextfield
            typographyText={cleanCategory}
            value={editedItemData?.cleanCategoryName}
            disabled={true}
          />
        )}

        {editedItemData?.unitListName !== unKnownText && (
          <StockModalTextfield
            typographyText={unitText}
            value={editedItemData?.unitListName}
            disabled={true}
          />
        )}

        <StockModalTextfield
          typographyText={
            editedItemData?.bags ? finishedBagsText : finishedKgText
          }
          value={editStockQuantity}
          disabled={false}
          handleChange={(e) =>
            handleChangeStockQuantity(setEditStockQuantity, finishedText, e)
          }
          errorText={editQuantityError?.finished ? onlyNumbersAreAllowed : ""}
        />

        <StockModalTextfield
          typographyText={
            editedItemData?.bags ? processingBagsText : processsingKgText
          }
          value={editProcessingQuantity}
          disabled={false}
          handleChange={(e) =>
            handleChangeStockQuantity(
              setEditProcessingQuantity,
              processingText,
              e,
            )
          }
          errorText={editQuantityError?.processing ? onlyNumbersAreAllowed : ""}
        />

        <StockUpdateModalActionButton
          handleCloseStockUpdateModal={handleCloseEditStockModal}
          updateButtonDisabled={isButtonDisabled}
          handleUpdateItem={handleEditItem}
        />
      </div>
    </Dialog>
  );
};
