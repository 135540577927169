import { uiTexts } from "./uiTexts";
import { RupeeIconWithLabel } from "../CommonComp/RupeeIconWithLabel";

// ==============================================|| GLOBAL UTILS ||============================================== //

const {
  labels: {
    brandNameText,
    quantityText,
    sNoText,
    itemNameText,
    rateText,
    partyText,
    bagText,
    weightText,
    partialText,
    selectText,
    dateText,
    deliveryRateText,
    taxText,
    amountText,
    netText,
    fareText,
    netFareText,
    brandText,
    dispatchedQuantityText,
  },
  buttonText: { noText },
} = uiTexts;

// Common Table Headers
const commonHeaders = [
  { label: sNoText },
  { label: dateText },
  { label: itemNameText },
  { label: `${quantityText} (${bagText})` },
  { label: weightText },
];

// Function to create Rupee Label Headers
const createRupeeHeader = (text, labelStyle) => ({
  label: <RupeeIconWithLabel text={text} labelClassName={labelStyle} />,
});

// Bill Table Headers
const billHeaders = [
  createRupeeHeader(rateText, "bill-modal-font bold-text"),
  createRupeeHeader(deliveryRateText, "bill-modal-font bold-text"),
  { label: "Tax %" },
  createRupeeHeader(`${taxText} ${amountText}`, "bill-modal-font bold-text"),
  createRupeeHeader(`${netText} ${amountText}`, "bill-modal-font bold-text"),
];

// Function to Generate Headers Dynamically
export const getMoveToBillHeaders = (
  isOrderDetails,
  customerResponsibility,
) => {
  const transportHeaders = [
    { label: brandNameText },
    ...(customerResponsibility === noText
      ? [
          createRupeeHeader(fareText, "bill-modal-font bold-text"),
          createRupeeHeader(netFareText, "bill-modal-font bold-text"),
        ]
      : []),
  ];

  return isOrderDetails
    ? [...commonHeaders, ...billHeaders]
    : [...commonHeaders, ...transportHeaders];
};

//Headers for orders page
export const customerOrderTableHeaders = [
  ...commonHeaders,
  { label: brandNameText },
  createRupeeHeader(rateText, "orders-text-header"),
  { label: partialText },
  { label: selectText },
  { label: "" },
];

//Headers for date wise display orders page
export const dateWiseOrderTableheaders = [
  { label: sNoText },
  { label: partyText },
  { label: itemNameText },
  {
    label: `${quantityText} (${bagText})`,
  },
  { label: weightText },
  { label: brandNameText },
  createRupeeHeader(rateText, "orders-text-header"),
];

export const timelineDispatchDetailsTableHeader = [
  sNoText,
  itemNameText,
  brandText,
  dispatchedQuantityText,
];
