import React from "react";
import Timeline, {
  TimelineHeaders,
  DateHeader,
  SidebarHeader,
} from "react-calendar-timeline";
import { Typography } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import moment from "moment";
import "../SalesTimeline.css";

// ======================================|| CUSTOMER TIMELINE DETAILS ||============================================== //

export const CustomerTimeLine = ({
  timelineGroups,
  timelineItems,
  handleTimeLineItemClick,
}) => {
  const {
    orderStatusLabels: { partialUpperCaseText, pendingUpperCaseText },
  } = uiTexts;

  const defaultTimeStart = moment().startOf("day").valueOf();
  const defaultTimeEnd = moment().add(1, "days").endOf("day").valueOf();

  const getBackgroundColor = (itemContext, orderType) => {
    if (orderType === partialUpperCaseText) {
      return "var(--timeline-partially-gradient)";
    } else if (orderType === pendingUpperCaseText) {
      return "white";
    } else {
      return itemContext.selected
        ? "var(--timeline-completed-color)"
        : "#dce7ac";
    }
  };

  const getBorderColor = (itemContext, orderType) => {
    if (orderType === pendingUpperCaseText) {
      return "var(--timeline-pending-color)";
    } else {
      return itemContext.selected
        ? "#dce7ac"
        : "var(--timeline-completed-color)";
    }
  };

  const itemRenderer = ({ item, itemContext, getItemProps }) => {
    const backgroundColor = getBackgroundColor(
      itemContext,
      item?.orderDispatchStatus,
    );
    const borderColor = getBorderColor(itemContext, item?.orderDispatchStatus);

    const color = itemContext.selected ? "#0e0e0d" : "#000000";

    return (
      <div
        {...getItemProps({
          style: {
            background: backgroundColor,
            color,
            border: `2px solid ${borderColor}`,
            height: 5,
            borderWidth: itemContext.selected ? 3 : 2,
            borderRadius: 12,
            fontSize: "0.8rem",
            paddingLeft: "0.5rem",
            cursor: "pointer",
          },
        })}
        onClick={(event) => handleTimeLineItemClick(item, event)}
      >
        <Typography className="gantt-item-text">{item.title}</Typography>
      </div>
    );
  };

  return (
    <Timeline
      groups={timelineGroups}
      items={timelineItems}
      defaultTimeStart={defaultTimeStart}
      defaultTimeEnd={defaultTimeEnd}
      timeSteps={{ day: 1 }}
      lineHeight={50}
      itemHeightRatio={0.7}
      stackItems
      fullUpdate
      canMove={false}
      canResize={false}
      canChangeGroup={false}
      itemRenderer={itemRenderer}
      minZoom={24 * 60 * 60 * 1000} // Set minimum zoom to 1 day
      maxZoom={7 * 24 * 60 * 60 * 1000} // Set maximum zoom to 1 week
    >
      <TimelineHeaders>
        <SidebarHeader>
          {({ getRootProps }) => {
            return (
              <div
                {...getRootProps({
                  style: {
                    width: 50,
                    color: "#03543f",
                    fontFamily: "sans-serif",
                    fontSize: "0.875rem",
                    marginTop: "0.35rem",
                  },
                })}
              >
                <Typography>Date</Typography>
              </div>
            );
          }}
        </SidebarHeader>

        <DateHeader unit="day" labelFormat="dddd LL" />
      </TimelineHeaders>
    </Timeline>
  );
};
