import React from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import {
  formatDate,
  capitalizeFirstLowercaseRest,
} from "../../../Utils/common";
import { uiTexts } from "../../../Utils/uiTexts";

// ============================================|| ACCORDION DETAILS SUMMARY TABLE ITEM ||============================================ //

export const AccordionDetailsSummaryTableItem = ({
  customerDetailsByIndex,
}) => {
  const {
    labels: { unKnownText },
  } = uiTexts;

  const InfoRow = ({ value }) => (
    <TableCell className="pendingAmount-text">{value}</TableCell>
  );

  const handleUnknownText = (value) => {
    if (!value || value.includes(unKnownText)) {
      return "";
    }
    return value;
  };

  return (
    <TableBody>
      {customerDetailsByIndex?.map((transaction, i) => (
        <TableRow key={i}>
          <InfoRow value={formatDate(transaction.orderDate)} />

          <InfoRow
            value={`${transaction.itemName} ${handleUnknownText(
              transaction.unitListId,
            )} ${handleUnknownText(
              capitalizeFirstLowercaseRest(transaction.cleanCategoryId),
            )}`}
          />

          <InfoRow
            value={capitalizeFirstLowercaseRest(transaction.brokerName)}
          />

          <InfoRow value={transaction.originalBag} />

          <InfoRow value={transaction.dispatchedBag} />

          <InfoRow value={transaction.pendingBag} />
        </TableRow>
      ))}
    </TableBody>
  );
};
