import React from "react";
import { TableBody, TableRow, TableCell } from "@mui/material";
import { uiTexts } from "../../../Utils/uiTexts";

// ==========================================|| KPI TABLE DETAILS ||=========================================== //

export const KpiTableContent = ({ data, column1Key, column2Key }) => {
  const {
    keyLabels: { numberText },
  } = uiTexts;

  const KpiDetailsTableCell = ({ label, isNegative }) => {
    return (
      <TableCell
        className="remove-border-bottom kpi-table-cell-padding"
        style={{ color: isNegative && "var(--common-error-color)" }}
      >
        {label}
      </TableCell>
    );
  };

  return (
    <TableBody>
      {data?.map((row, index) => {
        const isNegative =
          typeof row[column2Key] === numberText && row[column2Key] < 0;
        return (
          <TableRow key={index}>
            <KpiDetailsTableCell
              label={row[column1Key]}
              isNegative={isNegative}
            />

            <KpiDetailsTableCell
              label={row[column2Key]}
              isNegative={isNegative}
            />
          </TableRow>
        );
      })}
    </TableBody>
  );
};
