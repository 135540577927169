import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PendingOrdersTimelineModal } from "./PendingOrdersTimelineModal";
import { CustomerTimeLine } from "./CustomerTimeLine";
import { DispatchDetailsModal } from "./DispatchDetails/DispatchDetailsModal";
import dayjs from "dayjs";

// ======================================|| CUSTOMER TIMELINE DETAILS ||============================================== //

export const PendingCustomerTimeline = ({ timelineGroups, timelineItems }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isItemSelected, setIsItemSelected] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [pendingItems, setPendingItems] = useState([]);
  const [dispatchedItems, setDispatchedItems] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isTomorrow, setIsTomorrow] = useState(false);

  const isOpen = Boolean(anchorEl);

  const { customerPendingOrders, orderItemTransactionsData } = useSelector(
    (state) => state.data_menu,
  );

  const isPendingModal = isItemSelected && pendingItems?.length > 0;
  const isDispatchedModal =
    isItemSelected && isOpen && dispatchedItems?.length > 0;

  const checkIsTomorrow = (dateString) => {
    return dayjs(dateString).isSame(dayjs().add(1, "day"), "day");
  };

  const handleItemClick = async (item, event) => {
    setIsItemSelected(true);
    setSelectedCustomer("");
    setPendingItems([]);
    setSelectedItem(selectedItem?.id === item?.id ? null : item);
    setIsTomorrow(checkIsTomorrow(item?.date));

    const selectedCustomerOrders = customerPendingOrders?.find(
      (order) => order.partyId === item?.partyId,
    );
    setSelectedCustomer(selectedCustomerOrders);

    if (item?.orderDetails) {
      const orderItemTransactionIds = item?.orderDetails.map(
        (data) => data.orderItemTransactionId,
      );

      const results = orderItemTransactionsData?.filter((order) =>
        orderItemTransactionIds?.includes(order.id),
      );

      setPendingItems(results);
    }
    if (item?.dispatchHistoryDetails) {
      setAnchorEl(event.currentTarget);
      setDispatchedItems(item?.dispatchHistoryDetails);
    }
  };

  const handlePopoverClose = () => {
    setSelectedItem(null);
    setAnchorEl(null);
  };

  return (
    <div className="timeline-container list-container timeline-tab-height">
      <CustomerTimeLine
        timelineGroups={timelineGroups}
        timelineItems={timelineItems}
        handleTimeLineItemClick={handleItemClick}
      />

      {isPendingModal && (
        <PendingOrdersTimelineModal
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          pendingItems={pendingItems}
          setPendingItems={setPendingItems}
          setIsItemSelected={setIsItemSelected}
          isTomorrowInTimeLine={isTomorrow}
        />
      )}

      {isDispatchedModal && (
        <DispatchDetailsModal
          isOpen={isOpen}
          anchorEl={anchorEl}
          dispatchedItems={dispatchedItems}
          handlePopoverClose={handlePopoverClose}
        />
      )}
    </div>
  );
};
