import React from "react";
import { Table, TableContainer } from "@mui/material";
import { CustomerOrderListHeader } from "../../PendingCustomerOrdersDetails/CustomerOrderTableDetails/CustomerOrderListHeader";
import { DateWiseOrderTableDetails } from "./DateWiseOrderTableDetails";
import { dateWiseOrderTableheaders } from "../../../Utils/globalUtils";

// ===================================================|| CUSTOMER ORDER HEADER ||=================================================== //

export const DateWiseOrderTable = ({ dateWiseOrders }) => {
  return (
    <TableContainer className="customer-order-details-header list-container order-details-page-height table-container-style">
      <Table stickyHeader>
        <CustomerOrderListHeader headers={dateWiseOrderTableheaders} />

        <DateWiseOrderTableDetails dateWiseOrders={dateWiseOrders} />
      </Table>
    </TableContainer>
  );
};
