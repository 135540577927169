import React, { useState } from "react";
import { Dialog, Divider } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import { CustomerOrder } from "../../OrdersTab/PendingCustomerOrdersDetails/CustomerOrder";
import { BillDialogTitle } from "../../CommonComp/BillDialogTitle";
import { SuccessAlert } from "../../AlertDisplay/SuccessAlert";

// ===================================================|| ORDER TIMELINE MODAL ||=================================================== //

export const PendingOrdersTimelineModal = ({
  selectedCustomer,
  setSelectedCustomer,
  pendingItems,
  setPendingItems,
  setIsItemSelected,
  isTomorrowInTimeLine,
}) => {
  const {
    alertLabels: { billPlacedSuccessfullyText },
    labels: { billText },
  } = uiTexts;

  const [isOpenAlert, setIsOpenAlert] = useState(false);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={true}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "75rem",
          borderRadius: "20px",
          height: "auto",
        },
      }}
    >
      <BillDialogTitle
        onClose={() => setIsItemSelected(false)}
        titleLabel={billText}
      />

      <Divider className="bill-details-divider" />

      <CustomerOrder
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        orderItems={pendingItems}
        setOrderItems={setPendingItems}
        setIsOpenAlert={setIsOpenAlert}
        isTimelineModal={true}
        isTomorrowInTimeLine={isTomorrowInTimeLine}
        gridCss="customer-order-header-details-style timeline-modal-margin"
      />

      <SuccessAlert
        isOpenAlert={isOpenAlert}
        setIsOpenAlert={setIsOpenAlert}
        alertText={billPlacedSuccessfullyText}
      />
    </Dialog>
  );
};
