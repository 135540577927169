import React from "react";
import { Grid } from "@mui/material";
import { Loader } from "../../Loader/Loader";
import { DateWiseHeaderDetails } from "./DateWiseOrderRhsHeader/DateWiseHeaderDetails";
import { DateWiseOrderTable } from "./DateWiseOrderTable/DateWiseOrderTable";

// ===================================================|| CUSTOMER ORDER ||=================================================== //

export const DateWiseOrderRhs = ({
  selectedDate,
  setSelectedDate,
  dateWiseOrders,
  setDateWiseOrders,
}) => {
  return (
    <>
      {dateWiseOrders?.length === 0 ? (
        <Loader />
      ) : (
        selectedDate && (
          <Grid item xs={8.5}>
            <div className="order-list">
              <DateWiseHeaderDetails selectedDate={selectedDate} />

              <DateWiseOrderTable dateWiseOrders={dateWiseOrders} />
            </div>
          </Grid>
        )
      )}
    </>
  );
};
