import React from "react";
import { Grid } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { calculateTotal, formatAmount } from "../../Utils/common";
import { HeaderGrid } from "../../CommonComp/HeaderGrid";

// ===================================================|| PAYMENT LIST HEADER ||=================================================== //

export const PaymentListHeader = ({ filteredPayments }) => {
  const {
    labels: { customerNameText, pendingAmountText },
    keyLabels: { totalPendingAmountKey },
  } = uiTexts;

  return (
    <Grid
      container
      justifyContent="space-between"
      className="customer-list-header"
    >
      <HeaderGrid
        text={customerNameText}
        xs={10}
        count={filteredPayments?.length}
      />

      <HeaderGrid
        text={pendingAmountText}
        xs={2}
        count={
          <>
            <CurrencyRupeeIcon
              fontSize="15px"
              className="payment-currency-icon"
            />
            {formatAmount(
              calculateTotal(filteredPayments, totalPendingAmountKey),
              2,
            )}
          </>
        }
      />
    </Grid>
  );
};
