import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { uiTexts } from "../Utils/uiTexts";
import { getUserType } from "../Utils/common";
import Order from "../OrdersTab/Order";
import Payment from "../PaymentTab/Payment";
import EditBills from "../EditBills/EditBills";
import WarningPage from "../Warning/WarningPage";
import { HomePage } from "../HomePage/Home";
import Kpi from "../KpiPage/Kpi";
import Analysis from "../AnalysisPage/Analysis";

// ==============================|| ACCESS CONTROL ||============================== //

const AccessControl = () => {
  const {
    userTypesText: { salesTeamText, paymentTeamText },
    routingTexts: {
      orderLowerCaseText,
      paymentLowerCaseText,
      editBillsText,
      homeText,
      kpiLowerCaseText,
      analysisLowerCaseText,
    },
    warnings: { noAccessToThisPageText },
  } = uiTexts;

  const PATHS = [
    homeText,
    orderLowerCaseText,
    paymentLowerCaseText,
    editBillsText,
    kpiLowerCaseText,
    analysisLowerCaseText,
  ];

  const ACCESS_DENIED_PATHS = {
    [salesTeamText]: [
      paymentLowerCaseText,
      homeText,
      kpiLowerCaseText,
      analysisLowerCaseText,
    ],
    [paymentTeamText]: [
      orderLowerCaseText,
      editBillsText,
      homeText,
      kpiLowerCaseText,
      analysisLowerCaseText,
    ],
  };

  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");
  const [accessDenied, setAccessDenied] = useState(false);

  useEffect(() => {
    const currentPath = location.pathname;
    const activeTab = PATHS.find((path) => currentPath.includes(path)) || "";
    setActiveTab(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const userType = getUserType();
    const currentPath = location.pathname;
    const deniedPaths = ACCESS_DENIED_PATHS[userType] || [];
    const isAccessDenied = deniedPaths.some((path) =>
      currentPath.includes(path),
    );
    setAccessDenied(isAccessDenied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (accessDenied) {
    return <WarningPage warningLabel={noAccessToThisPageText} />;
  }

  const renderActiveTab = () => {
    switch (activeTab) {
      case homeText:
        return <HomePage />;
      case orderLowerCaseText:
        return <Order />;
      case paymentLowerCaseText:
        return <Payment />;
      case editBillsText:
        return <EditBills />;
      case kpiLowerCaseText:
        return <Kpi />;
      case analysisLowerCaseText:
        return <Analysis />;
      default:
        return null;
    }
  };

  return <>{renderActiveTab()}</>;
};
export default AccessControl;
