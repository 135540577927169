import React, { useState } from "react";
import { Grid } from "@mui/material";
import { CustomerOrder } from "./PendingCustomerOrdersDetails/CustomerOrder";
import { SuccessAlert } from "../AlertDisplay/SuccessAlert";
import { uiTexts } from "../Utils/uiTexts";
import { OrderListLhs } from "./OrderListLhs";
import { useSelector } from "react-redux";
import { DateWiseOrderRhs } from "../OrdersTab/DateWiseOrderDetails/DateWiseOrderRhs";
import { getFormattedDate } from "../Utils/common";
import "./OrderPage.css";

// ==============================================|| ORDERS PAGE DETAILS ||============================================== //

export const OrderPageDetails = ({
  filteredCustomers,
  setSelectedCustomer,
  selectedCustomer,
  orderLhsHeader,
  setOrderLhsHeader,
  selectedDate,
  setSelectedDate,
  uniqueDatesWithCount,
}) => {
  const {
    alertLabels: { billPlacedSuccessfullyText },
    placeHolderTexts: { dateInputFormat },
  } = uiTexts;

  const { orderDataByDate, orderItemTransactionsData } = useSelector(
    (state) => state.data_menu,
  );

  const [orderItems, setOrderItems] = useState([]);
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [dateWiseOrders, setDateWiseOrders] = useState([]);

  const handleSelectDate = (date) => {
    setSelectedDate(date);

    const filteredOrders = orderDataByDate?.filter((order) => {
      const formattedOrderDate = getFormattedDate(
        order.orderDate,
        dateInputFormat,
      );

      return formattedOrderDate === date.date;
    });

    setDateWiseOrders(filteredOrders);
  };

  const handleCustomerSelect = async (orders) => {
    setOrderItems([]);
    setSelectedCustomer(orders);

    const orderItemTransactionIds = orders?.orderItemTransactionIds;

    const results = orderItemTransactionsData?.filter((order) =>
      orderItemTransactionIds?.includes(order.id),
    );

    setOrderItems(results);
  };

  const orderPageGridCss =
    "customer-order-header-details-style customer-order-header-details-style-margin center-alignment";

  return (
    <Grid container className="order-payment-page-area order-margin order-list">
      <OrderListLhs
        filteredCustomers={filteredCustomers}
        setSelectedCustomer={setSelectedCustomer}
        selectedCustomer={selectedCustomer}
        handleCustomerSelect={handleCustomerSelect}
        orderLhsHeader={orderLhsHeader}
        setOrderLhsHeader={setOrderLhsHeader}
        uniqueDatesWithCount={uniqueDatesWithCount}
        handleSelectDate={handleSelectDate}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />

      {selectedCustomer && (
        <CustomerOrder
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          orderItems={orderItems}
          setOrderItems={setOrderItems}
          setIsOpenAlert={setIsOpenAlert}
          isTimelineModal={false}
          isTomorrowInTimeLine={false}
          gridCss={orderPageGridCss}
        />
      )}

      {selectedDate?.count > 0 && (
        <DateWiseOrderRhs
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          dateWiseOrders={dateWiseOrders}
          setDateWiseOrders={setDateWiseOrders}
        />
      )}

      <SuccessAlert
        isOpenAlert={isOpenAlert}
        setIsOpenAlert={setIsOpenAlert}
        alertText={billPlacedSuccessfullyText}
      />
    </Grid>
  );
};
