import React from "react";
import { uiTexts } from "../../../Utils/uiTexts";
import storeIcon from "../../../Image/storeIcon.png";
import shoppingCart from "../../../Image/shoppingCart.png";
import { CustomerOrderHeaderDetailItem } from "./CustomerOrderHeaderDetailItem";

// ===================================================|| CUSTOMER ORDER HEADER DETAILS ||=================================================== //

export const CustomerOrderHeaderDetails = ({
  selectedCustomer,
  customerDetailsSelect,
  billClick,
  setBillClick,
  gridCss,
}) => {
  const {
    labels: { customerNameText, numberOfItemsOrderedText },
  } = uiTexts;

  const handleClick = () => setBillClick(true);
  const isDisabled = customerDetailsSelect?.length === 0 || billClick;

  return (
    <>
      <CustomerOrderHeaderDetailItem
        icon={storeIcon}
        label={customerNameText}
        value={selectedCustomer?.partyName}
        isBill={false}
        disabled={false}
        onClick={handleClick}
        gridCss={gridCss}
      />

      <CustomerOrderHeaderDetailItem
        icon={shoppingCart}
        label={numberOfItemsOrderedText}
        value={selectedCustomer?.totalPendingOrders}
        isBill={true}
        disabled={isDisabled}
        onClick={handleClick}
        gridCss={gridCss}
      />
    </>
  );
};

export default CustomerOrderHeaderDetails;
