import React from "react";
import { Stack, IconButton } from "@mui/material";
import {
  KeyboardArrowUpOutlined,
  KeyboardArrowDownOutlined,
} from "@mui/icons-material";

// ===================================================|| HEADER ARROW ICONS ||=================================================== //

export const HeaderArrowIcons = ({ handleSort, sortOrder }) => {
  const ArrowButton = ({ onClick, disabled, Icon }) => (
    <IconButton
      size="small"
      className="header-arrow-icons"
      onClick={onClick}
      disabled={disabled}
    >
      <Icon fontSize="small" />
    </IconButton>
  );

  return (
    <Stack spacing={0} className="center-alignment">
      <ArrowButton
        onClick={handleSort}
        disabled={sortOrder}
        Icon={KeyboardArrowUpOutlined}
      />

      <ArrowButton
        onClick={handleSort}
        disabled={!sortOrder}
        Icon={KeyboardArrowDownOutlined}
      />
    </Stack>
  );
};
