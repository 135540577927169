import React from "react";
import { Grid, Divider } from "@mui/material";
import { OrderListHeader } from "../OrdersTab/PendingOrders/OrderListHeader";
import { OrderListDetails } from "./PendingOrders/OrderListDetails";
import { DateWiseOrderLhsItem } from "./DateWiseOrderDetails/DateWiseOrderLhsItem";
import "./OrderPage.css";

// ===================================================|| ORDERS LHS ||=================================================== //

export const OrderListLhs = ({
  filteredCustomers,
  setSelectedCustomer,
  selectedCustomer,
  handleCustomerSelect,
  orderLhsHeader,
  setOrderLhsHeader,
  uniqueDatesWithCount,
  handleSelectDate,
  selectedDate,
  setSelectedDate,
}) => {
  return (
    <Grid item xs={3.5} className="order-grid">
      <OrderListHeader
        headerDetails={filteredCustomers}
        orderLhsHeader={orderLhsHeader}
        setOrderLhsHeader={setOrderLhsHeader}
        setSelectedDate={setSelectedDate}
        setSelectedCustomer={setSelectedCustomer}
      />

      <Divider className="customer-list-header-divider" />

      <div className="list-container order-tab-height">
        {orderLhsHeader ? (
          <OrderListDetails
            selectedCustomer={selectedCustomer}
            handleCustomerSelect={handleCustomerSelect}
            filteredCustomers={filteredCustomers}
          />
        ) : (
          <DateWiseOrderLhsItem
            selectedDate={selectedDate}
            handleSelectDate={handleSelectDate}
            uniqueDates={uniqueDatesWithCount}
          />
        )}
      </div>
    </Grid>
  );
};
