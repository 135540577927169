import React, { useEffect } from "react";
import moment from "moment";
import { Loader } from "../Loader/Loader";
import { PendingCustomerTimeline } from "../OrderTimeline/TimelineDetails/PendingCustomerTimeline";
import { TimelineLegend } from "./TimelineLegend";
import { useSelector } from "react-redux";
import { uiTexts } from "../Utils/uiTexts";
import useRetrieveFunctions from "../CustomHooks/useRetrieveFunctions";
import "./SalesTimeline.css";

// ======================================|| SALES TIMELINE ||============================================== //

export const SalesTimeline = () => {
  const {
    labels: { ordersText },
    orderStatusLabels: { completedUpperCaseText },
  } = uiTexts;

  const {
    retrieveAllOrderItemTransactions,
    retrievePendingOrdersForTimeline,
    retrieveDispatchOrdersForTimeline,
  } = useRetrieveFunctions();

  useEffect(() => {
    retrieveAllOrderItemTransactions();

    retrievePendingOrdersForTimeline();
    retrieveDispatchOrdersForTimeline();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { timelinePendingOrders, timelineDispatchOrders } = useSelector(
    (state) => state.data_menu,
  );
  const orderItemTransactionsDataStatusCode = useSelector(
    (state) => state.status_menu.orderItemTransactionsDataStatusCode,
  );

  const groups = [{ id: 1, title: ordersText }];

  const createGanttItems = (orders, isDispatch) => {
    return orders?.map((order, index) => {
      const isEven = index % 2 === 0;
      const startTime = moment(order.date)
        .startOf("day")
        .add(isEven ? 1.5 : 12, "hours")
        .valueOf();

      const endTime = moment(order.date)
        .startOf("day")
        .add(isEven ? 11 : 22.5, "hours")
        .valueOf();

      return {
        id: index + 1,
        group: 1,
        title: `${order.partyName} (${
          isDispatch
            ? order.dispatchHistoryDetails?.length
            : order.orderDetails?.length
        })`,
        start_time: startTime,
        end_time: endTime,
        partyId: order.partyId,
        date: order.date,
        orderDispatchStatus: isDispatch
          ? completedUpperCaseText
          : order.orderDispatchStatus,
        ...(isDispatch
          ? { dispatchHistoryDetails: order.dispatchHistoryDetails }
          : { orderDetails: order.orderDetails }),
      };
    });
  };

  const dispatchItems = createGanttItems(timelineDispatchOrders, true);
  const pendingItems = createGanttItems(timelinePendingOrders, false);

  const ganttItems = [...dispatchItems, ...pendingItems]?.map((item, index) => ({
    ...item,
    id: index + 1,
  }));

  return (
    <>
      {!orderItemTransactionsDataStatusCode ? (
        <Loader />
      ) : (
        <>
          <TimelineLegend />

          <PendingCustomerTimeline
            timelineGroups={groups}
            timelineItems={ganttItems}
          />
        </>
      )}
    </>
  );
};
