import React from "react";
import { TableHead, TableCell, Typography, TableRow } from "@mui/material";
import { uiTexts } from "../../../Utils/uiTexts";

// =================================|| STOCK RHS CONTENT TABLE HEADER ||===================================== //

export const StockRhsContentTableHeader = () => {
  const {
    labels: {
      brandNameText,
      sNoText,
      itemNameText,
      dateText,
      unitText,
      cleanCategory,
      totalBagsText,
      dispatchedBagsText,
    },
    headerLabels: { pendingBagsText },
  } = uiTexts;

  const headers = [
    { label: sNoText },
    { label: dateText },
    { label: itemNameText },
    { label: brandNameText },
    { label: cleanCategory },
    { label: unitText },
    { label: totalBagsText },
    { label: dispatchedBagsText },
    { label: pendingBagsText },
    { label: "" },
    { label: "" },
  ];

  return (
    <TableHead>
      <TableRow>
        {headers?.map((header, index) => (
          <TableCell key={index} className="customer-list-details-divider">
            <Typography className="orders-text-header">
              {header.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
