import React from "react";
import { FormControl } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { uiTexts } from "../../Utils/uiTexts";
import dayjs from "dayjs";

// ======================================|| STOCK RHS CONTENT DATE FILTER ||================================== //

export const StockRhsContentDateFilter = ({
  filteredDate,
  setFilteredDate,
  handleStockDateSelect,
  isDisabled,
}) => {
  const {
    placeHolderTexts: { dateInputFormat },
  } = uiTexts;

  return (
    <FormControl className="date-field-style date-field-style-margin">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={filteredDate ? dayjs(filteredDate) : null}
          format={dateInputFormat}
          className="date-field-style"
          onChange={(newValue) =>
            handleStockDateSelect(newValue, setFilteredDate)
          }
          slotProps={{
            textField: {
              sx: {
                "& .MuiInputBase-root": {
                  height: "45px",
                },
              },
            },
          }}
          disabled={isDisabled}
        />
      </LocalizationProvider>
    </FormControl>
  );
};
