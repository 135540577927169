import React from "react";
import { Table, TableContainer } from "@mui/material";
import { KpiDetailsTableHeader } from "./KpiDetailsTableHeader";
import { KpiTableContent } from "./KpiTableContent";

// ==========================================|| KPI TABLE DETAILS ||=========================================== //

export const KpiTable = ({
  data,
  negativeValuesCount,
  column1Key,
  column2Key,
  tableHeaderText,
}) => {
  return (
    <TableContainer className="kpi-details-table-style">
      <Table stickyHeader>
        <KpiDetailsTableHeader
          tableHeaderText={tableHeaderText}
          negativeValuesCount={negativeValuesCount}
        />

        <KpiTableContent
          data={data}
          column1Key={column1Key}
          column2Key={column2Key}
        />
      </Table>
    </TableContainer>
  );
};
