import React, { useState } from "react";
import { Grid } from "@mui/material";
import { StockLhs } from "./StockLhs";
import { StockRhs } from "../StockRhsContent/StockRhs";
import useRetrieveFunctions from "../../CustomHooks/useRetrieveFunctions";
import { uiTexts } from "../../Utils/uiTexts";
import { getFormattedDate } from "../../Utils/common";
import dayjs from "dayjs";
import "../../OrdersTab/OrderPage.css";

// ==============================================|| STOCK LHS LIST ||================================================ //

export const StockLhsList = ({
  filteredCategories,
  selectedCategory,
  setSelectedCategory,
}) => {
  const {
    placeHolderTexts: { dateAndTimeInputFormat, todayDateAndTimeFormat },
  } = uiTexts;

  const [filteredDate, setFilteredDate] = useState(
    dayjs().format(todayDateAndTimeFormat),
  );

  const [updatedItemData, setUpdatedItemData] = useState([]);
  const { retrieveStockByCategory } = useRetrieveFunctions();

  const handleSelectCategory = (category) => {
    setUpdatedItemData([]);
    setSelectedCategory(category);
    setFilteredDate(dayjs().format(todayDateAndTimeFormat));

    retrieveStockByCategory(category?.id);
  };

  const handleStockDateSelect = (value, setDate) => {
    const formattedDate = getFormattedDate(value, dateAndTimeInputFormat);
    setDate(formattedDate);
  };

  return (
    <Grid
      container
      className="order-payment-page-area payment-margin order-list"
    >
      <StockLhs
        filteredCategories={filteredCategories}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        handleSelectCategory={handleSelectCategory}
      />

      {selectedCategory && (
        <StockRhs
          selectedCategory={selectedCategory}
          handleStockDateSelect={handleStockDateSelect}
          setFilteredDate={setFilteredDate}
          filteredDate={filteredDate}
          updatedItemData={updatedItemData}
          setUpdatedItemData={setUpdatedItemData}
        />
      )}
    </Grid>
  );
};
