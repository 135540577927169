import React from "react";
import { Grid, Divider } from "@mui/material";
import { PaymentListHeader } from "./PendingPayment/PaymemtListHeader";
import { PaymentListDetails } from "./PendingPayment/PaymentListDetails";
import "./PaymentPage.css";

// ===================================================|| PAYMENT PAGE DETAILS ||=================================================== //

export const PaymentPageLhs = ({
  filteredPayments,
  setSelectedPayment,
  selectedPayment,
  handlePayment,
}) => {
  return (
    <Grid item xs={6} className="payment-grid">
      <PaymentListHeader filteredPayments={filteredPayments} />

      <Divider className="customer-list-header-divider" />

      <div className="list-container payment-tab-height">
        <PaymentListDetails
          selectedPayment={selectedPayment}
          handlePayment={handlePayment}
          filteredPayments={filteredPayments}
        />
      </div>
    </Grid>
  );
};
