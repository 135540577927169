import React, { useState } from "react";
import {
  TableBody,
  TableCell,
  TableRow,
  Typography,
  IconButton,
} from "@mui/material";
import { uiTexts } from "../../../Utils/uiTexts";
import { formatDate } from "../../../Utils/common";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { ConfirmationModal } from "../../../CommonComp/ConfirmationModal";
import { handleUnknown } from "../../../Utils/common";

// =======================================|| STOCK RHS CONTENT TABLE ITEM ||=========================================== //

export const StockRhsContentTableItem = ({
  updatedItemData,
  handleOpenEditStockModal,
  handleRemoveStock,
}) => {
  const {
    separators: { hyphenText },
    labels: { kgText, areYouSureYouWantToDeleteThisStockText, unKnownText },
  } = uiTexts;

  const [isDeleteStock, setIsDeleteStock] = useState(false);
  const [deleteStockData, setDeleteStockData] = useState();

  const TableCellContent = ({ content }) => (
    <TableCell className="orders-table-cell-style">
      <Typography className="commodity-details-text">
        {content ? content : hyphenText}
      </Typography>
    </TableCell>
  );

  const handleRemoveSelectedStock = () => {
    handleRemoveStock(deleteStockData);
    setIsDeleteStock(false);
  };

  const handleRemoveIconClick = (items) => {
    setDeleteStockData(items);
    setIsDeleteStock(true);
  };

  const getBagsDetails = (bagsDetails) => {
    if (!bagsDetails) {
      return { total: null, dispatched: null, pending: null };
    }

    const getBagValue = (bagCount, quantityInKgs) =>
      bagCount !== null && bagCount !== undefined
        ? `${bagCount}`
        : `${quantityInKgs} ${kgText}`;

    return {
      total: getBagValue(
        bagsDetails?.totalBags,
        bagsDetails?.totalQuantityInKgs,
      ),
      dispatched: getBagValue(
        bagsDetails?.dispatchedBags,
        bagsDetails?.dispatchedQuantityInKgs,
      ),
      pending: getBagValue(
        bagsDetails?.pendingBags,
        bagsDetails?.pendingQuantityInKgs,
      ),
    };
  };

  return (
    <>
      <TableBody>
        {[...updatedItemData]
          .sort((a, b) => new Date(a.date) - new Date(b.date))
          .map((items, index) => {
            const bagsDetails =
              items.stockNonRawItemCounts !== null
                ? items.stockNonRawItemCounts
                : items.stockRawItemCounts;

            const normalizedBags = getBagsDetails(bagsDetails);

            return (
              <TableRow key={items.id || index}>
                <TableCellContent content={index + 1} />

                <TableCellContent content={formatDate(items?.date)} />

                <TableCellContent
                  content={handleUnknown(unKnownText, items?.itemName)}
                />

                <TableCellContent
                  content={handleUnknown(unKnownText, items?.brandName)}
                />

                <TableCellContent
                  content={handleUnknown(unKnownText, items?.cleanCategoryName)}
                />

                <TableCellContent
                  content={handleUnknown(unKnownText, items?.unitListName)}
                />

                <TableCellContent
                  content={handleUnknown(unKnownText, normalizedBags?.total)}
                />
                <TableCellContent
                  content={handleUnknown(
                    unKnownText,
                    normalizedBags?.dispatched,
                  )}
                />

                <TableCellContent
                  content={handleUnknown(unKnownText, normalizedBags?.pending)}
                />

                <TableCell className="orders-table-cell-style">
                  <IconButton onClick={() => handleOpenEditStockModal(items)}>
                    <EditOutlinedIcon />
                  </IconButton>
                </TableCell>

                <TableCell className="orders-table-cell-style">
                  <IconButton onClick={() => handleRemoveIconClick(items)}>
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>

      {isDeleteStock && (
        <ConfirmationModal
          isOpenModal={isDeleteStock}
          handleCloseModal={() => setIsDeleteStock(false)}
          removeItem={() => handleRemoveSelectedStock()}
          modalHeaderText={areYouSureYouWantToDeleteThisStockText}
        />
      )}
    </>
  );
};
