import React, { useRef, useEffect } from "react";
import PendingBillHeader from "./PendingBillHeader";
import { BillModalPaymentDetails } from "../BillDetails Modal/Modal Payment Details/BillModalPaymentDetails";
import { uiTexts } from "../../Utils/uiTexts";
import { useSelector } from "react-redux";
import { formatDate, formatAmount, handleUnknown } from "../../Utils/common";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import "../PaymentPage.css";

// ===================================================|| BILL MODAL HEADER ||=================================================== //

export const BillModalHeader = ({
  orderItemTransactionIdData,
  filteredBillDetails,
  isPaymentDetailsModalOpen,
  setPaymentDetailsModalOpen,
}) => {
  const paymentDetailsModalRef = useRef(null);

  const handleInfoIconClick = () => {
    setPaymentDetailsModalOpen(true);
  };

  const { selectedBillDetails } = useSelector((state) => state.data_menu);
  const {
    labels: {
      itemNameText,
      totalText,
      pendingText,
      amountText,
      billedDateText,
      paidText,
      firmNameText,
    },
    toolTipText: { paymentDetailsText },
  } = uiTexts;

  useEffect(() => {
    const closePaymentDetailsModal = (event) => {
      if (
        paymentDetailsModalRef.current &&
        !paymentDetailsModalRef.current.contains(event.target)
      ) {
        setPaymentDetailsModalOpen(false);
      }
    };

    if (isPaymentDetailsModalOpen) {
      window.addEventListener("mousedown", closePaymentDetailsModal);
    }

    return () => {
      window.removeEventListener("mousedown", closePaymentDetailsModal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentDetailsModalOpen]);

  return (
    <>
      <PendingBillHeader
        label={billedDateText}
        value={formatDate(selectedBillDetails?.billedDate)}
      />

      <PendingBillHeader
        label={itemNameText}
        value={
          Array.isArray(orderItemTransactionIdData)
            ? orderItemTransactionIdData
                ?.map(
                  (item) =>
                    `${item.itemName} ${handleUnknown(
                      item.unitListName,
                    )} ${handleUnknown(item.cleanCategoryName)}`,
                )
                .join(", ")
            : ""
        }
      />

      <PendingBillHeader
        label={firmNameText}
        value={selectedBillDetails?.firmName}
      />

      <PendingBillHeader
        label={`${totalText} ${amountText}`}
        value={
          <>
            <CurrencyRupeeIcon className="payment-currency-icon billList-currency-icon" />
            {formatAmount(selectedBillDetails?.totalAmount, 2)}
          </>
        }
      />

      <PendingBillHeader
        label={`${paidText} ${amountText}`}
        value={
          <>
            <CurrencyRupeeIcon className="payment-currency-icon billList-currency-icon" />
            {formatAmount(selectedBillDetails?.paidAmount, 2)}

            {selectedBillDetails?.paidAmount !== 0 && (
              <Tooltip title={paymentDetailsText} arrow>
                <InfoOutlinedIcon
                  className="payment-modal-info-icon"
                  onClick={handleInfoIconClick}
                />
              </Tooltip>
            )}
          </>
        }
      />

      <PendingBillHeader
        label={`${pendingText} ${amountText}`}
        value={
          <>
            <CurrencyRupeeIcon className="payment-currency-icon billList-currency-icon" />
            <b>{formatAmount(selectedBillDetails?.totalPendingAmount, 2)}</b>
          </>
        }
      />

      {isPaymentDetailsModalOpen && (
        <div className="payment-details-modal" ref={paymentDetailsModalRef}>
          <BillModalPaymentDetails filteredBillDetails={filteredBillDetails} />
        </div>
      )}
    </>
  );
};
