import React from "react";
import { Table, TableContainer } from "@mui/material";
import { OrderSelectedDetailsTableHeader } from "../../OrderSelectedTable/OrderSelectedDetailsTableHeader";
import { TransportDetailsTableItem } from "../TransportDetailsTableItem";
import { getMoveToBillHeaders } from "../../../../Utils/globalUtils";

// ===================================================|| TRANSPORT DETAILS ||=================================================== //

export const TransportDetailsTable = ({
  dummyOrder,
  setDummyOrder,
  handleFieldChange,
  customerResponsibility,
  setGatePassData,
  gatePassData,
  setExpandedRows,
  expandedRows,
  isEditBillModal,
  selectedTransport,
  setBillUpdateError,
}) => {
  const headers = getMoveToBillHeaders(false, customerResponsibility);

  return (
    <div className="order-details-modal-table bill-modal-transport-table">
      <TableContainer>
        <Table>
          <OrderSelectedDetailsTableHeader headers={headers} />

          <TransportDetailsTableItem
            dummyOrder={dummyOrder}
            setDummyOrder={setDummyOrder}
            handleFieldChange={handleFieldChange}
            customerResponsibility={customerResponsibility}
            setGatePassData={setGatePassData}
            gatePassData={gatePassData}
            setExpandedRows={setExpandedRows}
            expandedRows={expandedRows}
            isEditBillModal={isEditBillModal}
            selectedTransport={selectedTransport}
            setBillUpdateError={setBillUpdateError}
          />
        </Table>
      </TableContainer>
    </div>
  );
};
