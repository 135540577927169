import React from "react";
import {
  Box,
  MenuItem,
  FormControl,
  Select,
  IconButton,
  InputAdornment,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

// =============================================|| ORDER DETAILS TABLE SELECT FIELD ||============================================== //

export const OrderSelectedTableSelectField = ({
  index,
  taxValue,
  handleTax,
  taxList,
}) => {
  return (
    <Box className="tax-field-dropdown-width">
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          size="small"
          value={taxValue ?? ""}
          onChange={(e) => handleTax(e, index)}
          endAdornment={
            taxValue && (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  sx={{ ml: -5 }}
                  onClick={() => handleTax({ target: { value: "" } }, index)}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            )
          }
        >
          {taxList?.map((tax) => (
            <MenuItem key={tax.id} value={tax.taxValues}>
              {tax.taxValues}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
