import React, { useState } from "react";
import { Grid } from "@mui/material";
import { DateInput } from "../BillDetails Modal/Input Fields/DateInput";
import { AmountInput } from "../BillDetails Modal/Input Fields/AmountInput";
import { DeductionInput } from "./Input Fields/DeductionInput";
import { PaymentActionButtons } from "../BillDetails Modal/Input Fields/PaymentActionButtons";
import { useSelector } from "react-redux";
import { isEmptyField } from "../../Utils/common";

// ===================================================|| BILL INPUTS ||=================================================== //

export const BillInputs = ({
  selectedDate,
  setSelectedDate,
  amount,
  setAmount,
  handleUpdate,
  handleCancel,
  setUpdateDate,
  setDeductionType,
}) => {
  const { selectedBillDetails } = useSelector((state) => state.data_menu);
  const [updateClick, setUpdateClick] = useState(false);

  const handleAmount = (e) => {
    setAmount(e.target.value);
  };

  //Check if amount exceeds pending amount
  const amountError =
    isNaN(amount) ||
    amount > selectedBillDetails?.totalPendingAmount ||
    amount < 0;

  const isUpdateDisabled =
    selectedDate === null ||
    isEmptyField(amount) ||
    amountError ||
    updateClick === true ||
    selectedBillDetails?.totalPendingAmount === 0;

  return (
    <Grid container spacing={2} className="payment-details-modal-table">
      <DateInput
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        setUpdateDate={setUpdateDate}
      />

      <AmountInput
        amount={amount}
        handleAmount={handleAmount}
        amountError={amountError}
      />

      <DeductionInput setDeductionType={setDeductionType} />

      <PaymentActionButtons
        handleUpdate={handleUpdate}
        handleCancel={handleCancel}
        isUpdateDisabled={isUpdateDisabled}
        updateClick={updateClick}
        setUpdateClick={setUpdateClick}
      />
    </Grid>
  );
};
