import React from "react";
import { Stack, Typography, Box } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

// ==========================================|| RUPEE ICON WITH LABEL ||========================================== //

export const RupeeIconWithLabel = ({ text, labelClassName }) => {
  return (
    <Stack alignItems="flex-start">
      <Typography className={labelClassName}>{text}</Typography>
      <Box display="flex" alignItems="center">
        <Typography>(</Typography>
        <CurrencyRupeeIcon fontSize="10px" />
        <Typography>)</Typography>
      </Box>
    </Stack>
  );
};
