import React from "react";
import { TableBody, TableCell, TableRow, Typography } from "@mui/material";
import {
  formatAmount,
  handleUnknown,
  extractNumericValue,
} from "../../../Utils/common";
import { uiTexts } from "../../../Utils/uiTexts";

// ===================================================|| CUSTOMER ORDER DETAILS ||=================================================== //

export const DateWiseOrderTableDetails = ({ dateWiseOrders }) => {
  const {
    separators: { hyphenText },
    labels: { unknownCategoryText, unKnownUnitListText, unKnownBrandText },
  } = uiTexts;

  // Helper function to render TableCell content
  const TableCellContent = ({ content, tooltip }) => (
    <TableCell className="orders-table-cell-style">
      <Typography className="commodity-details-text">{content}</Typography>
    </TableCell>
  );

  return (
    <TableBody>
      {dateWiseOrders?.map((details, index) => {
        return (
          <TableRow key={details.id}>
            <TableCellContent content={index + 1} />

            <TableCellContent content={details.partyName} />

            <TableCellContent
              content={`${details.itemName} ${handleUnknown(
                unKnownUnitListText,
                details.unitListName,
              )} ${handleUnknown(
                unknownCategoryText,
                details.cleanCategoryName,
              )}`}
            />

            <TableCellContent
              content={
                details.quantityInKg === null ? details.bags : hyphenText
              }
            />

            <TableCellContent
              content={
                details.quantityInKg === null
                  ? details.bags * extractNumericValue(details.unitListName)
                  : details.quantityInKg
              }
            />

            <TableCellContent
              content={handleUnknown(
                unKnownBrandText,
                details.brandName,
                hyphenText,
              )}
            />

            <TableCellContent content={formatAmount(details.rate, 2)} />
          </TableRow>
        );
      })}
    </TableBody>
  );
};
