import React, { useState, useEffect } from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { useSelector } from "react-redux";
import useRetrieveFunctions from "../CustomHooks/useRetrieveFunctions";
import { Loader } from "../Loader/Loader";
import { EditBillHeader } from "./EditBillContent/EditBillHeader";
import { EditBillContent } from "./EditBillContent/EditBillContent";
import "../CustomersTab/CustomerPage.css";
import "../EditBills/editbills.css";

// ============================================|| EDIT BILLS ||============================================ //

const EditBills = () => {
  const {
    retrieveSalesParametersData,
    retrieveAllBillsData,
    retrieveAllPendingBillsData,
    retrieveTaxList,
  } = useRetrieveFunctions();

  useEffect(() => {
    retrieveSalesParametersData();
    retrieveAllBillsData();
    retrieveAllPendingBillsData();
    retrieveTaxList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { pendingBillsData, billsData, salesParametersData } = useSelector(
    (state) => state.data_menu,
  );

  const [searchList, setSearchList] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState();

  const filteredCustomersData = pendingBillsData?.filter((party) =>
    party.partyName.toLowerCase().includes(searchList.toLowerCase()),
  );

  const isLoader =
    pendingBillsData?.length === 0 &&
    billsData?.length === 0 &&
    salesParametersData?.length === 0;

  return (
    <>
      <Header />

      {isLoader ? (
        <Loader />
      ) : (
        <>
          <EditBillHeader
            searchList={searchList}
            setSearchList={setSearchList}
            setSelectedData={setSelectedCustomer}
          />

          <EditBillContent
            searchList={searchList}
            setSelectedCustomer={setSelectedCustomer}
            selectedCustomer={selectedCustomer}
            filteredCustomersData={filteredCustomersData}
          />
        </>
      )}

      <Footer />
    </>
  );
};

export default EditBills;
