import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Typography, ButtonGroup } from "@mui/material";
import { OrderPage } from "./OrderPage";
import { SalesTimeline } from "../OrderTimeline/SalesTimeline";
import { capitalizeFirstLowercaseRest } from "../Utils/common";
import { uiTexts } from "../Utils/uiTexts";
import { Loader } from "../Loader/Loader";
import useRetrieveFunctions from "../CustomHooks/useRetrieveFunctions";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";

// ============================================|| ORDER ||============================================ //

const Order = () => {
  const {
    orderStatusLabels: { timelineText, billUpdateText },
  } = uiTexts;

  const [activeComponent, setActiveComponent] = useState(timelineText);

  const { customerPendingOrderStatusCode, customerStatusCode } = useSelector(
    (state) => state.status_menu,
  );

  const isOrderPageLoading =
    !customerPendingOrderStatusCode || !customerStatusCode;

  const { retrieveSalesParametersData, retrieveCustomerData, retrieveTaxList } =
    useRetrieveFunctions();

  useEffect(() => {
    retrieveSalesParametersData();
    retrieveCustomerData();
    retrieveTaxList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getButtonStyles = (component) => {
    const isActive = activeComponent === component;
    const backgroundColor = isActive ? "var(--common-color)" : "transparent";
    const color = isActive ? "white" : "#006e1b";
    const hoverBackgroundColor = isActive ? "#006e1b" : "#f0f0f0";
    const textTransform = "none";

    return {
      backgroundColor,
      color,
      textTransform,
      borderColor: "var(--common-color)",
      "&:hover": {
        backgroundColor: hoverBackgroundColor,
        color,
        borderColor: "var(--common-color)",
      },
    };
  };

  const CustomButton = ({ component, label }) => {
    return (
      <Button
        onClick={() => setActiveComponent(component)}
        variant={activeComponent === component ? "contained" : "outlined"}
        sx={getButtonStyles(component)}
      >
        <Typography className="bill-modal-font">
          {capitalizeFirstLowercaseRest(component)}
        </Typography>
      </Button>
    );
  };

  return (
    <>
      <Header />
      {isOrderPageLoading ? (
        <Loader />
      ) : (
        <>
          <div>
            <ButtonGroup className="order-button-group">
              <CustomButton component={timelineText} />

              <CustomButton component={billUpdateText} />
            </ButtonGroup>
          </div>

          <div>
            {activeComponent === timelineText && <SalesTimeline />}

            {activeComponent === billUpdateText && <OrderPage />}
          </div>
        </>
      )}
      <Footer />
    </>
  );
};

export default Order;
