import React from "react";
import { Button, Grid, ListItem, Typography, Stack } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import "../OrderPage.css";

// ==========================================|| DATE WISE ORDER LIST DETAILS ||======================================== //

export const DateWiseOrderLhsItem = ({
  selectedDate,
  handleSelectDate,
  uniqueDates,
}) => {
  const InfoGridItem = ({ xsValue, isIcon, label }) => {
    return (
      <Grid item xs={xsValue}>
        <Stack direction="row">
          {isIcon && (
            <CalendarTodayIcon className="storeIcon-style date-icon-color" />
          )}

          <Typography className="customer-details-list-text">
            {label}
          </Typography>
        </Stack>
      </Grid>
    );
  };

  return (
    <>
      {uniqueDates?.map((value) => (
        <Button
          key={value.date}
          className={`orders-payment-content--button ${
            selectedDate?.date === value.date
              ? "orders-payment-content--button--selected"
              : ""
          }`}
          onClick={() => handleSelectDate(value)}
        >
          <ListItem>
            <Grid container>
              <InfoGridItem xsValue={9} isIcon={true} label={value.date} />

              <InfoGridItem xsValue={3} isIcon={false} label={value.count} />
            </Grid>
          </ListItem>
        </Button>
      ))}
    </>
  );
};
