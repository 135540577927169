import React, { useEffect } from "react";
import { TableContainer, Table } from "@mui/material";
import { ReportTableHeader } from "./ReportTableHeader";
import { ReportTableItem } from "./ReportTableItem";
import { useSelector, useDispatch } from "react-redux";
import {
  SelectedReportData,
  SelectedFirmName,
  SelectedCategoryName,
} from "../../../Store/Reducers/data_menu";
import { EmptyReportData } from "../EmptyReportData";
import { uiTexts } from "../../../Utils/uiTexts";
import {
  capitalizeFirstLowercaseRest,
  handleUnknown,
} from "../../../Utils/common";

// ============================================|| REPORT TABLE ||============================================ //

export const ReportTable = ({
  reportHeader,
  reportType,
  selectedFirm,
  selectedCategory,
  reportDate,
}) => {
  const dispatch = useDispatch();

  const {
    labels: { unKnownAreaText },
    separators: { hyphenText },
    keyLabels: { firmNameKey, categoryNameKey },
    warnings: { noMatchingDataWasFoundText },
    placeHolderTexts: { dateFormat },
  } = uiTexts;

  const {
    billsByDateData,
    selectedReportData,
    firmNames,
    categoryList,
    dispatchReportData,
  } = useSelector((state) => state.data_menu);

  const getNameById = (id, list, nameKey) => {
    const result = list?.find((item) => item.id === id);
    return result ? result[nameKey] : null;
  };

  const formattedDate = reportDate?.format(dateFormat);

  useEffect(() => {
    if (
      (reportType === 3 && dispatchReportData.length === 0) ||
      ((reportType === 1 || reportType === 2) &&
        (!billsByDateData || billsByDateData.length === 0))
    ) {
      dispatch(SelectedReportData([]));
      return;
    }

    if (reportType === 1) {
      const data_one = billsByDateData?.map((data, index) => ({
        sNo: index + 1,
        partyArea: handleUnknown(unKnownAreaText, data.partyArea, hyphenText),
        partyName: data.partyName,
        itemName: capitalizeFirstLowercaseRest(data.itemName),
        unitListName: capitalizeFirstLowercaseRest(data.unitListName),
        cleanCategoryName: capitalizeFirstLowercaseRest(data.cleanCategoryName),
        brandName: data.brandName,
        dispatchedQuantity:
          data.dispatchedQuantity === null ? data.bag : data.dispatchedQuantity,
        transportName: data.transportName,
      }));

      dispatch(SelectedReportData(data_one));
    } else if (reportType === 2) {
      const data_two = billsByDateData
        ?.filter((data) => {
          const firmName = getNameById(selectedFirm, firmNames, firmNameKey);
          const itemCategory = getNameById(
            selectedCategory,
            categoryList,
            categoryNameKey,
          );

          dispatch(SelectedFirmName(firmName));
          dispatch(SelectedCategoryName(itemCategory));

          const isFirmSelected = selectedFirm && firmName;
          const isCategorySelected = selectedCategory && itemCategory;

          return (
            (!isFirmSelected || data.firmName === firmName) &&
            (!isCategorySelected || data.itemCategory === itemCategory)
          );
        })
        ?.map((data, index) => ({
          sNo: index + 1,
          partyArea: handleUnknown(unKnownAreaText, data.partyArea, hyphenText),
          partyName: data.partyName,
          itemName: capitalizeFirstLowercaseRest(data.itemName),
          unitListName: capitalizeFirstLowercaseRest(data.unitListName),
          cleanCategoryName: capitalizeFirstLowercaseRest(
            data.cleanCategoryName,
          ),
          brandName: data.brandName,
          dispatchedQuantity:
            data.dispatchedQuantity === null
              ? data.bag
              : data.dispatchedQuantity,
          rate: data.rate,
          billNumber: data.billNumber,
          totalBillAmount: data.totalBillAmount,
        }));

      dispatch(SelectedReportData(data_two));
    } else if (reportType === 3) {
      const data_three = dispatchReportData
        ?.filter((data) => {
          const dispatchedDate = data?.dispatchedDate.split("T")[0];
          return dispatchedDate === formattedDate;
        })
        ?.map((report, index) => ({
          sNo: index + 1,
          area: handleUnknown(unKnownAreaText, report.areaName, hyphenText),
          partyArea: report.partyName,
          itemName: capitalizeFirstLowercaseRest(report.itemName),
          unit: report.itemUnit,
          type: report.itemType,
          brandName: report.brandName,
          quantity: report.quantity,
          gatePassNumber: report.gatePassNumber,
          transportName: report.transportName,
          vehicleNumber: report.vehicleNumber,
        }));

      dispatch(SelectedReportData(data_three));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    billsByDateData,
    dispatchReportData,
    reportType,
    selectedFirm,
    selectedCategory,
    reportDate,
  ]);

  return (
    <>
      {selectedReportData?.length === 0 ? (
        <EmptyReportData warningLabel={noMatchingDataWasFoundText} />
      ) : (
        <TableContainer
          className={`report-details-container list-container table-container-style${
            reportType === 1
              ? "report-one-table-height"
              : "report-two-table-height"
          }`}
        >
          <Table className="report-table-style">
            <ReportTableHeader reportHeader={reportHeader} />

            <ReportTableItem />
          </Table>
        </TableContainer>
      )}
    </>
  );
};
