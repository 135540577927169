import React from "react";
import { Popover, Table, TableContainer } from "@mui/material";
import { DispatchDetailsTableHeader } from "./DispatchDetailsTableHeader";
import { DispatchDetailsTableItem } from "./DispatchDetailsTableItem";

// ======================================|| DISPATCH DETAILS MODAL ||============================================== //

export const DispatchDetailsModal = ({
  isOpen,
  dispatchedItems,
  anchorEl,
  handlePopoverClose,
}) => {
  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      disableRestoreFocus
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <TableContainer className="gantt-item-table-container">
        <Table stickyHeader>
          <DispatchDetailsTableHeader />

          <DispatchDetailsTableItem dispatchedItems={dispatchedItems} />
        </Table>
      </TableContainer>
    </Popover>
  );
};
