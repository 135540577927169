import React from "react";
import { uiTexts } from "../../../Utils/uiTexts";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import shoppingCart from "../../../Image/shoppingCart.png";
import { DateHeaderItem } from "./DateHeaderItem";

// ===================================================|| CUSTOMER ORDER ||=================================================== //

export const DateWiseHeaderDetails = ({ selectedDate }) => {
  const {
    labels: { dateText, numberOfItemsOrderedText },
  } = uiTexts;
  return (
    <>
      <DateHeaderItem
        label={dateText}
        value={selectedDate?.date}
        icon={CalendarTodayIcon}
      />

      <DateHeaderItem
        label={numberOfItemsOrderedText}
        value={selectedDate?.count}
        icon={shoppingCart}
      />
    </>
  );
};
