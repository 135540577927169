import React from "react";
import { Grid, Stack } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import { calculateTotal } from "../../Utils/common";
import { HeaderGrid } from "../../CommonComp/HeaderGrid";
import { HeaderArrowIcons } from "./HeaderArrowIcons";
import { useSelector } from "react-redux";

// ===================================================|| ORDER LIST HEADER ||=================================================== //

export const OrderListHeader = ({
  headerDetails,
  orderLhsHeader,
  setOrderLhsHeader,
  setSelectedDate,
  setSelectedCustomer,
}) => {
  const {
    labels: { customerNameText, pendingOrdersText, dateText, ordersText },
    keyLabels: { totalPendingOrdersKey },
  } = uiTexts;

  const orderDataByDate = useSelector(
    (state) => state.data_menu.orderDataByDate,
  );

  const handleSort = () => {
    setSelectedDate([]);
    setSelectedCustomer();
    setOrderLhsHeader(!orderLhsHeader);
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      className="customer-list-header"
    >
      <Grid item xs={9}>
        <Stack direction="row" className="center-alignment" spacing={1}>
          <HeaderArrowIcons
            handleSort={handleSort}
            sortOrder={orderLhsHeader}
          />

          <HeaderGrid
            text={orderLhsHeader ? customerNameText : dateText}
            {...(orderLhsHeader && { count: headerDetails?.length })}
          />
        </Stack>
      </Grid>

      <Grid item xs={3}>
        <HeaderGrid
          text={orderLhsHeader ? pendingOrdersText : ordersText}
          count={
            orderLhsHeader
              ? calculateTotal(headerDetails, totalPendingOrdersKey)
              : orderDataByDate?.length
          }
        />
      </Grid>
    </Grid>
  );
};
