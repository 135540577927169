import React from "react";
import { Grid } from "@mui/material";
import { StockRhsContentHeader } from "./StockRhsContentHeader";
import { StockRhsContentDetails } from "./StockRhsContentDetails";

// =========================================|| STOCK RHS CONTENT ||============================================= //

export const StockRhsContent = ({
  selectedCategory,
  handleStockDateSelect,
  handleOpenStockUpdateModal,
  filteredDate,
  setFilteredDate,
  handleClearFilter,
  updatedItemData,
  handleOpenEditStockModal,
  handleRemoveStock,
}) => {
  return (
    <Grid item xs={9}>
      <StockRhsContentHeader
        selectedCategory={selectedCategory}
        handleOpenStockUpdateModal={handleOpenStockUpdateModal}
      />

      <StockRhsContentDetails
        handleStockDateSelect={handleStockDateSelect}
        filteredDate={filteredDate}
        setFilteredDate={setFilteredDate}
        handleClearFilter={handleClearFilter}
        updatedItemData={updatedItemData}
        handleOpenEditStockModal={handleOpenEditStockModal}
        handleRemoveStock={handleRemoveStock}
      />
    </Grid>
  );
};
