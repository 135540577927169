import React from "react";
import { useSelector } from "react-redux";
import { uiTexts } from "../../Utils/uiTexts";
import { KpiDetailsTable } from "./KpiTable/KpiDetailsTable";

// ==========================================|| KPI LHS ||=========================================== //

export const KpiLhs = ({ selectedKpi }) => {
  const {
    orderToBillDelayDetails,
    billToDispatchDelayDetails,
    billToPaymentDelayDetails,
  } = useSelector((state) => state.kpi_menu);

  const {
    kpiCardsLabels: {
      orderToBillAverageText,
      orderToBillText,
      bagWiseDispatchAverage,
      billToPaymentAverageText,
      billToPaymentText,
      billToDispatchText,
      bagBelow20Text,
      bag20To100Text,
      bag100To200Text,
      bagAbove200Text,
      bagQuantitylessThan20Text,
      bagQuantityBetween20to100Text,
      bagQuantityBetween100to200Text,
      bagQuantityMoreThan200Text,
      bagTypeText,
    },
    separators: { hyphenText },
    labels: { commodityText, bagText },
    keyLabels: { categoryNameKey },
  } = uiTexts;

  const billToDispatchBagWise = billToDispatchDelayDetails?.bagWise
    ? Object.entries(billToDispatchDelayDetails?.bagWise).map(
        ([key, value]) => {
          let description;
          switch (key) {
            case bagBelow20Text:
              description = bagQuantitylessThan20Text;
              break;
            case bag20To100Text:
              description = bagQuantityBetween20to100Text;
              break;
            case bag100To200Text:
              description = bagQuantityBetween100to200Text;
              break;
            case bagAbove200Text:
              description = bagQuantityMoreThan200Text;
              break;
            default:
              break;
          }
          return {
            bagType: description,
            billToDispatch: value !== null ? value : hyphenText,
          };
        },
      )
    : [];

  //Modify negative values to zero only for payment delay
  const updatedBillToPaymentDelayDetails =
    billToPaymentDelayDetails?.categoryWise?.map((item) => ({
      ...item,
      billToPayment: Math.max(item.billToPayment, 0),
    })) || [];

  return (
    <>
      {selectedKpi?.index === 0 && (
        <KpiDetailsTable
          data={orderToBillDelayDetails?.categoryWise}
          headerText={orderToBillAverageText}
          column1Text={commodityText}
          column1Key={categoryNameKey}
          column2Key={orderToBillText}
          isSubheader={true}
        />
      )}

      {selectedKpi?.index === 1 && (
        <KpiDetailsTable
          data={billToDispatchBagWise}
          headerText={bagWiseDispatchAverage}
          column1Text={bagText}
          column1Key={bagTypeText}
          column2Key={billToDispatchText}
          isSubheader={false}
        />
      )}

      {selectedKpi?.index === 2 && (
        <KpiDetailsTable
          data={updatedBillToPaymentDelayDetails}
          headerText={billToPaymentAverageText}
          column1Text={commodityText}
          column1Key={categoryNameKey}
          column2Key={billToPaymentText}
          isSubheader={true}
        />
      )}
    </>
  );
};
