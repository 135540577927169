// This file will be used for storing all the text and labels.
export const uiTexts = Object.freeze({
  labels: {
    userNameText: "Username",
    passwordText: "Password",
    categoryText: "Category",
    brandText: "Brand",
    itemsText: "Items",
    customersText: "Customers",
    rsText: "Rs.",
    kgText: "Kg.",
    amountText: "amount",
    amountUpperText: "Amount",
    totalText: "Total",
    paidText: "Paid",
    pendingText: "Pending",
    dateText: "Date",
    itemNameText: "Item name",
    netText: "Net",
    outStandingText: "Outstanding",
    firmNameText: "Firm name",
    paymentText: "Payment",
    ordersText: "Orders",
    quantityText: "Quantity",
    sNoText: "S.No",
    partyText: "Party",
    partialText: "Partial",
    selectText: "Select",
    remarksText: "Remarks",
    billText: "Bill",
    orderedDateText: "Ordered date",
    dispatchedDateText: "Dispatched date",
    customerNameText: "Customer name",
    pendingOrdersText: "Pending orders",
    pendingAmountText: "Pending amount",
    numberOfItemsOrderedText: "Number of items ordered",
    billNumberText: "Bill number",
    yourCommentsText: "Your comments",
    taxText: "Tax",
    brandNameText: "Brand name",
    brokerNameText: "Broker name",
    orderedBagsText: "Ordered bags",
    totalBagsText: "Total bags",
    dispatchedBagsText: "Dispatched bags",
    dispatchedQuantityText: "Dispatched quantity",
    areaText: "Area",
    addresstext: "Address",
    contactNumberText: "Contact number",
    gstNumberText: "GST number",
    panNumberText: "PAN number",
    unitText: "Unit",
    rateText: "Rate (100 Kg's)",
    bagText: "Bag",
    billedDateText: "Billed date",
    offText: "off",
    unKnownText: "Unknown",
    unknownCategoryText: "Unknown Category",
    unKnownUnitListText: "Unknown UnitList",
    unKnownBrandText: "Unknown Brand",
    unKnownAreaText: "Unknown Area",
    ratetext: "rate",
    rateUpperText: "Rate",
    pendingBagText: "pendingBag",
    taxAmountText: "taxAmount",
    weightText: "Weight(Kg.)",
    deductionText: "Deduction",
    cashDiscountText: "Cash Discount",
    qualityDifferenceText: "Quality Difference",
    tdsText: "TDS",
    typeText: "Type",
    unitInKgText: "Unit(Kg.)",
    lorryNameText: "Lorry name",
    transportNameText: "Transport name",
    lorryText: "lorry",
    transportText: "transport",
    fareText: "Fare",
    netFareText: "Net fare",
    deliveryRateText: "Delivery rate",
    customerResponsibilityText: "Customer responsibility",
    gatePassNumberText: "Gatepass number",
    vehicleNumberText: "Vehicle number",
    quantityLowerCaseText: "quantity",
    vehicleNumberLowerCaseText: "vehicleNumber",
    advancePaymentRequiredText: "Advance payment required",
    deliveryDateIsApplicableText: "Delivery date is applicable",
    discountRateIsApplicableText: "Discount rate is applicable",
    dotPdfText: ".pdf",
    editBillsText: "Edit bills",
    bagLowerCaseText: "bag",
    pendingBillsText: "Pending bills",
    confirmationText: "Confirmation",
    areYouSureYouWantToDeleteThisOrderText:
      "Are you sure you want to delete this order ?",
    areYouSureYouWantToDeleteThisStockText:
      "Are you sure you want to delete this stock ?",
    deliveryRateLowerCaseText: "deliveryRate",
    stringText: "string",
    startDateText: "Start date",
    endDateText: "End date",
    commodityText: "Commodity",
    customerText: "Customer",
    averageText: "Average",
    cleanCategory: "Clean category",
    numberOfBags: "Number of bags",
    quantityInKg: "Quantity in KG",
    fullyCompletedUpperCaseText: "FULLY_COMPLETED",
    sourceText: "Source",
    destinationText: "Destination",
    virudhunagarText: "Virudhunagar",
    tamilNaduText: "Tamilnadu",
    loadingText: "Loading ...",
  },

  keyLabels: {
    totalPendingOrdersKey: "totalPendingOrders",
    totalPendingAmountKey: "totalPendingAmount",
    firmNameKey: "firmName",
    categoryNameKey: "categoryName",
    userNameText: "userName",
    userTypeText: "userType",
    tabValueText: "tabValue",
    enGBText: "en-GB",
    enINText: "en-IN",
    numericText: "numeric",
    longText: "long",
    numberText: "number",
    fareAmountText: "fareAmount",
    netFareAmountText: "netFareAmount",
    rawUpperCaseText: "(RAW)",
    finishedText: "finished",
    processingText: "processing",
  },

  warnings: {
    usernameAndPassWordRequiredText: "UserName and password is required",
    usernameIsRequiredText: "Username is required",
    passwordIsRequiredText: "Password is required",
    invalidCredentialsText: "Invalid credentials",
    noMoreDataText: "No more data",
    someThingWentWrongText: "Something went wrong",
    noDetailsAvailableForThisCustomerText:
      "No details available for this customer.",
    noDetailsAvailableForThisItemText: "No details available for this Item.",
    inValidValueText: "Invalid value",
    verifyThePendingAmountText: "Verify the pending amount",
    customerNotFoundText: "Customer not found",
    ItemNotFoundText: "Item not found",
    noPendingBagsText: "No pending bags.",
    nobillsForSelectedDateText: "No bills for selected date",
    noMatchingDataWasFoundText: "No matching data was found",
    onlyNumbersAllowedText: "Only numbers allowed",
    verifyTheQuantityText: "Verify the quantity",
    onlyAlphabetsAreAllowedText: "Only alphabets are allowed.",
    noAccessToThisPageText: "No access to this page.",
    pageNotFoundText: "Page not found.",
    errorText: "Error",
    startDateMustBeEarlierThanEndDateText:
      "Start date must be earlier than end date",
    endDateMustBeLaterThanStartDateText:
      "End date must be later than start date",
    startDateAndEndDateCannotBeSameText:
      "Start date and end date cannot be same",
    categoryNotFoundText: "Category not found",
    onlyNumbersAreAllowed: "Only numbers are allowed",
    countOfNegativeAverageText: "Count of negative average",
    stockAlreadyExistsText: "Stock already exists.",
    updateStockText: "Update stock",
  },

  buttonText: {
    loginText: "Login",
    moveToBillText: " Move to bill",
    yesText: "Yes",
    updateText: "Update",
    cancelText: "Cancel",
    reportText: "Report",
    downloadText: "Download",
    clearText: "Clear",
    noText: "No",
  },

  separators: {
    hyphenText: "-",
    leftParenthesisText: " (",
    rightParenthesisText: ") ",
    colonText: " : ",
    underscoreText: "_",
    commaText: ", ",
    percentageText: "%",
    ellipsisText: "...",
  },

  footerLabels: {
    footerText: "PCS AGRI TECH - Virudhunagar 626 001",
  },

  headerLabels: {
    agriTechUpperCaseText: "AGRI TECH",
    pendingBagsText: "Pending bags",
    logoutText: "Logout",
  },

  placeHolderTexts: {
    enterUserNameText: "Enter username",
    enterPasswordText: "Enter password",
    enterAmontText: "Enter amount",
    chooseDateAndTimeText: "Choose date and time",
    enterYourNameText: "Enter your name",
    enterBillNumberText: "Enter bill number",
    chooseFirmText: "Choose firm",
    addYourCommentsText: "Add your comments",
    searchCustomerText: "Search customer",
    searchItemText: "Search item",
    chooseReportText: "Choose report",
    chooseDateText: "Choose date",
    chooseCategoryText: "Choose category",
    dateInputFormat: "DD-MM-YYYY",
    dateTimeDisplayFormat: "DD-MM-YYYY    HH:mm:ss",
    dateAndTimeInputFormat: "YYYY-MM-DDTHH:mm:ss.SSS",
    todayDateAndTimeFormat: "YYYY-MM-DDT00:00:00.000",
    dateFormat: "YYYY-MM-DD",
    selectParty: "Select party",
    ifNecessaryText: "if necessary",
    enterLorryName: "Enter lorry name",
    chooseTransportText: "Choose transport",
    enterQuantityText: "Enter quantity",
    enterVehicleNumberText: "Enter vehicle number",
    searchCategory: "Search category",
    chooseItem: "Choose item",
    chooseBrand: "Choose brand",
    chooseCleanCategory: "Choose clean category",
    chooseUnit: "Choose unit",
    enterNumberOfBags: "Enter number of bags",
    finishedKgText: "Finished Kg.",
    finishedBagsText: "Finished bags",
    processsingKgText: "Processing Kg.",
    processingBagsText: "Processing bags",
    selectDate: "Select date",
    chooseCustomerText: "Choose customer",
    chooseRangeText: "Choose range",
  },

  alertLabels: {
    billPlacedSuccessfullyText: "Bill placed successfully !",
    paymentWasMadeSuccessfullyText: "Payment was made successfully !",
    clickAwayText: "clickaway",
    infoText: "Info",
    customersHaveMissingInformationText: "customers have missing information.",
    missingDetailsText: "Missing details",
    isMissingText: " is missing.",
    selectTheStageText: "Select the stage",
    noDataText: "No data available",
    chooseBothTheStartAndEndDateOrNeitherText:
      "Choose both the start and end date or neither",
  },

  deductionValues: {
    normalUpperCaseText: "NORMAL",
    cashDiscountUpperCaseText: "CASH_DISCOUNT",
    qualityDifferenceUpperCaseText: "QUALITY_DIFFERENCE",
  },

  toolTipText: {
    paymentDetailsText: "Payment Details",
    moreInfoText: "More Info",
    backText: "Back",
    profileText: "Profile",
  },

  userTypesText: {
    adminText: "ADMIN",
    salesTeamText: "SALES_TEAM",
    paymentTeamText: "PAYMENT_TEAM",
    orderTeamText: "ORDER_TEAM",
  },

  routingTexts: {
    orderLowerCaseText: "orders",
    paymentLowerCaseText: "payment",
    editBillsText: "editBills",
    homeText: "home",
    kpiLowerCaseText: "kpi",
    stockLowerCaseText: "stock",
    analysisLowerCaseText: "analysis",
  },

  kpiCardsLabels: {
    orderToBillAverageText: "Order to bill average",
    billToDispatchAverageText: "Bill to dispatch average",
    billToPaymentAverageText: "Bill to payment average",
    dispatchToDeliveryAverageText: "Dispatch to delivery average",
    bagWiseDispatchAverage: "Bag wise dispatch average",
    transportWiseDispatchAverage: "Transport wise dispatch average",
    partyNameText: "partyName",
    orderToBillText: "orderToBill",
    transportNameLowerCaseText: "transportName",
    bagTypeText: "bagType",
    billToDispatchText: "billToDispatch",
    billToPaymentText: "billToPayment",
    bagBelow20Text: "bagBelow20",
    bag20To100Text: "bag20To100",
    bag100To200Text: "bag100To200",
    bagAbove200Text: "bagAbove200",
    bagQuantitylessThan20Text: "Bag quantity less than 20",
    bagQuantityBetween20to100Text: "Bag quantity between 20 and 100",
    bagQuantityBetween100to200Text: "Bag quantity between 100 and 200",
    bagQuantityMoreThan200Text: "Bag quantity more than 200",
  },

  salesDelayTypeLabels: {
    goodUpperCaseText: "GOOD",
    averageUpperCaseText: "AVERAGE",
    badUpperCaseText: "BAD",
  },

  chartLabels: {
    salesSummaryText: "Sales Summary",
    orderedDetailsText: "Ordered details",
    dispatchDetailsText: "Dispatch details",
    paymentDetailsText: "Payment details",
    brandWiseDetailsText: "Brandwise Details",
    billedBagsText: "Billed bags",
    quantityBagText: "Quantity (Bags)",
    barText: "bar",
    roundedText: "rounded",
    periodText: "Period",
    lineText: "line",
    smoothText: "smooth",
    delayText: "Delay",
    pieText: "pie",
    donutText: "donut",
    commodityWiseOrdersText: "Commodity wise orders",
    totalOrdersText: "Total orders ",
    totalAmountText: "Total amount",
    totalItemOrderText: "Total items order",
    daysText: "days",
    itemTypeText: "Item type",
    unitNameText: "Unit name",
    ofOrdersText: "of orders",
    monthText: "month",
    dayText: "day",
  },

  stockTabKeys: {
    brandIdsText: "brandIds",
    cleanCategoryIdsText: "cleanCategoryIds",
    unitIdsText: "unitIds",
    itemIdText: "itemId",
    itemNameText: "itemName",
    idText: "id",
    brandNameText: "brandName",
    unitNameText: "unitName",
    cleanCategoryNameText: "cleanCategoryName",
  },

  orderStatusLabels: {
    partiallyCompletedText: "Partailly completed",
    timelineText: "Timeline",
    billUpdateText: "Bill update",
    dispatchedText: "Dispatched",
    completedUpperCaseText: "COMPLETED",
    partialUpperCaseText: "PARTIAL",
    pendingUpperCaseText: "PENDING",
  },
});
