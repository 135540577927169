import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { uiTexts } from "../Utils/uiTexts";
import { SearchField } from "../CommonComp/SearchField";
import { PaymentPageDetails } from "./PaymentPageDetails";
import "./PaymentPage.css";

// ===================================================|| PAYMENT PAGE ||=================================================== //

export const PaymentPage = () => {
  const {
    placeHolderTexts: { searchCustomerText },
    warnings: { customerNotFoundText },
  } = uiTexts;

  const customerPendingPayments = useSelector(
    (state) => state.data_menu.customerPendingPayments,
  );

  const [searchList, setSearchList] = useState("");
  const [selectedPayment, setSelectedPayment] = useState();

  // Filtering Customers
  const filteredPayments = customerPendingPayments?.filter((customer) =>
    customer.partyName.toLowerCase().includes(searchList.toLowerCase()),
  );

  const isEmptyData =
    customerPendingPayments?.length !== 0 &&
    (filteredPayments?.length === 0 || filteredPayments?.length === undefined);

  return (
    <Stack>
      <div className="customer-search-container-order">
        <SearchField
          searchList={searchList}
          setSearchList={setSearchList}
          searchLabel={searchCustomerText}
          setSelectedData={setSelectedPayment}
        />
      </div>

      {isEmptyData ? (
        <Typography className="order-customer-not-found">
          {customerNotFoundText}
        </Typography>
      ) : (
        <PaymentPageDetails
          filteredPayments={filteredPayments}
          setSelectedPayment={setSelectedPayment}
          selectedPayment={selectedPayment}
        />
      )}
    </Stack>
  );
};
