import React from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { StockRhsContentDateFilter } from "./StockRhsContentDateFilter";
import { StockRhsContentTable } from "./StockRhsContentTable/StockRhsContentTable";
import { uiTexts } from "../../Utils/uiTexts";
import { useSelector } from "react-redux";

// =========================================|| STOCK RHS CONTENT ||============================================= //

export const StockRhsContentDetails = ({
  handleStockDateSelect,
  filteredDate,
  setFilteredDate,
  handleClearFilter,
  updatedItemData,
  handleOpenEditStockModal,
  handleRemoveStock,
}) => {
  const {
    warnings: { updateStockText },
  } = uiTexts;

  const categoryWiseStockData = useSelector(
    (state) => state.data_menu.categoryWiseStockData,
  );
  const stockByCategoryStatus = useSelector(
    (state) => state.status_menu.stockByCategoryStatus,
  );
  return (
    <>
      <Stack direction="row">
        <StockRhsContentDateFilter
          filteredDate={filteredDate}
          setFilteredDate={setFilteredDate}
          handleStockDateSelect={handleStockDateSelect}
          isDisabled={categoryWiseStockData?.length === 0}
        />

        {filteredDate !== null && filteredDate !== undefined && (
          <IconButton
            onClick={() => handleClearFilter()}
            className="remove-filtered-date-icon"
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        )}
      </Stack>

      {updatedItemData?.length ? (
        <StockRhsContentTable
          updatedItemData={updatedItemData}
          handleOpenEditStockModal={handleOpenEditStockModal}
          handleRemoveStock={handleRemoveStock}
        />
      ) : (
        stockByCategoryStatus && (
          <Typography className="no-data-text no-stock-text-font">
            {updateStockText}
          </Typography>
        )
      )}
    </>
  );
};
