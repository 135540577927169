import auth from "../Utils/auth";
import { Buffer } from "buffer";
import axios from "axios";
import API_URL from "../Utils/http.common";

const createApiService = () => {
  return axios.create({
    baseURL: API_URL,
  });
};

const apiService = createApiService();
const encodedCredentials = Buffer.from(
  auth.username + ":" + auth.password,
).toString("base64");

// ========================================|| STOCK SERVICE ||======================================== //

const stockDetails = "stockDetails";
const ganttChart = "GanttChart";

export const getStockDetailsByCategory = async (id) => {
  return apiService.get(`/${stockDetails}/${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const updateStock = async (data) => {
  return apiService.post(`/${stockDetails}`, data, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const editStock = async (id, data) => {
  return apiService.put(`/${stockDetails}/editStockItem/${id}`, data, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const deleteStock = async (id) => {
  return apiService.delete(`/${stockDetails}/deleteStockItem/${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getPendingOrdersForTimeline = () => {
  return apiService.get(`/${ganttChart}/pendingOrdersAnalysis`, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getDispatchOrdersForTimeline = () => {
  return apiService.get(`/${ganttChart}/dispatchHistoryAnalysis`, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Basic " + encodedCredentials,
    },
  });
};
