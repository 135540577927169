import React from "react";
import { Grid, Divider } from "@mui/material";
import { StockLhsListHeader } from "./StockLhsListHeader";
import { StockLhsListItem } from "./StockLhsListItem";
import "../../OrdersTab/OrderPage.css";

// ==============================================|| STOCK LHS LIST ||================================================ //

export const StockLhs = ({
  filteredCategories,
  selectedCategory,
  setSelectedCategory,
  handleSelectCategory,
}) => {
  return (
    <Grid item xs={3} className="order-grid">
      <StockLhsListHeader filteredCategories={filteredCategories} />

      <Divider className="customer-list-header-divider" />

      <StockLhsListItem
        selectedCategory={selectedCategory}
        handleSelectCategory={handleSelectCategory}
        filteredCategories={filteredCategories}
      />
    </Grid>
  );
};
