import React from "react";
import { Paper, Typography } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import { formatAmount } from "../../Utils/common";
import Chart from "react-apexcharts";
import "../Analysis.css";

// ==============================================|| COMMODITY BAR CHART ||============================================= //

export const PaymentChartDetails = ({ paymentDetails }) => {
  const {
    chartLabels: { paymentDetailsText, barText, roundedText, periodText },
    labels: {
      pendingAmountText,
      totalText,
      amountText,
      paidText,
      rsText,
      amountUpperText,
    },

    alertLabels: { noDataText },
  } = uiTexts;

  const chartData = {
    labels: paymentDetails?.map((amount) => amount.period),
    series: [
      {
        name: `${totalText} ${amountText}`,
        data: paymentDetails?.map((amount) => amount.totalAmount),
      },
      {
        name: `${paidText} ${amountText}`,
        data: paymentDetails?.map((amount) => amount.paidAmount),
      },
      {
        name: pendingAmountText,
        data: paymentDetails?.map((amount) => amount.pendingAmount),
      },
    ],
  };
  const colorCodes = ["#0195FE", "#4BB58F", "#FFCF01"];

  const options = {
    chart: {
      type: barText,
      height: 350,
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "70%",
        endingShape: roundedText,
      },
    },
    xaxis: {
      categories: chartData?.labels,
      title: {
        text: periodText,
        style: {
          color: "var(--analysis-chart-label-color)",
        },
      },
    },
    yaxis: {
      title: {
        text: `${amountUpperText} ( ${rsText} )`,
        style: {
          color: "var(--analysis-chart-label-color)",
        },
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `${rsText} ${formatAmount(val,0)}`,
      },
    },
    colors: colorCodes,
    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
  };

  return (
    <Paper className="chart-summary-background">
      <Typography className="chart-header-text">
        {paymentDetailsText}
      </Typography>

      <div>
        {chartData?.labels?.length === 0 ? (
          <div className="no-data-text">
            <Typography>{noDataText}</Typography>
          </div>
        ) : (
          <Chart
            options={options}
            series={chartData?.series}
            type={barText}
            width="100%"
            height="350"
          />
        )}
      </div>
    </Paper>
  );
};
