import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { uiTexts } from "../../../Utils/uiTexts";

// ===================================================|| CUSTOMER ORDER HEADER DETAILS ITEM ||=================================================== //

export const CustomerOrderHeaderDetailItem = ({
  icon,
  label,
  value,
  isBill,
  disabled,
  onClick,
  gridCss,
}) => {
  const {
    buttonText: { moveToBillText },
  } = uiTexts;

  const buttonCss = `moveToBill-button ${
    disabled
      ? "moveToBill-button-disabled-bg-color"
      : "moveToBill-button-bg-color"
  }`;

  return (
    <Grid container className={gridCss}>
      <Grid item xs={3}>
        <Typography className="customer-header-list-text">
          <img src={icon} className="storeIcon-style" alt="icon" />

          {label}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography
          className="customer-header-details-list-text"
          align="center"
        >
          {value}
        </Typography>
      </Grid>

      {isBill && (
        <Grid item xs={3} container justifyContent="flex-end">
          <Button className={buttonCss} onClick={onClick} disabled={disabled}>
            {moveToBillText}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
