import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import { uiTexts } from "../Utils/uiTexts";

// ======================================|| TIMELINE LEGEND ||============================================== //

export const TimelineLegend = () => {
  const {
    labels: { pendingText },
    orderStatusLabels: { partiallyCompletedText, dispatchedText },
  } = uiTexts;

  const legendItems = [
    {
      label: pendingText,
      color: "",
      borderColor: "var(--timeline-pending-color)",
    },
    {
      label: partiallyCompletedText,
      color: "var(--timeline-partially-gradient)",
      borderColor: "var(--timeline-completed-color)",
    },
    {
      label: dispatchedText,
      color: "var(--timeline-completed-color)",
      borderColor: "var(--timeline-completed-color)",
    },
  ];

  return (
    <Stack className="timeline-legend-stack-outer" direction="row" spacing={2}>
      {legendItems?.map((item, index) => (
        <Stack
          key={index}
          direction="row"
          className="center-alignment"
          spacing={1}
        >
          <Box
            sx={{
              width: 20,
              height: 20,
              background: item.color,
              borderRadius: "5px",
              border: `2px solid ${item.borderColor}`,
            }}
          />

          <Typography className="timeline-legend-text">{item.label}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};
