import React from "react";
import { TableBody, TableRow, TableCell } from "@mui/material";
import { handleUnknown } from "../../../Utils/common";
import { uiTexts } from "../../../Utils/uiTexts";

// ======================================|| DISPATCH TABLE ITEM ||============================================== //

export const DispatchDetailsTableItem = ({ dispatchedItems }) => {
  const {
    separators: { hyphenText },
    labels: { unKnownText, kgText },
  } = uiTexts;

  const DispatchTableRow = ({ value }) => (
    <TableCell className="remove-border-bottom gantt-item-table-padding">
      {value}
    </TableCell>
  );

  return (
    <TableBody>
      {dispatchedItems?.map((item, index) => (
        <TableRow key={item.orderItemTransactionId || index}>
          <DispatchTableRow value={index + 1} />

          <DispatchTableRow
            value={`${item.itemName} ${handleUnknown(
              unKnownText,
              item.unitListName,
            )}
            ${handleUnknown(unKnownText, item.cleanCategoryName)}`}
          />

          <DispatchTableRow
            value={handleUnknown(unKnownText, item.brandName, hyphenText)}
          />

          <DispatchTableRow
            value={handleUnknown(
              unKnownText,
              item.bagsDispatched || `${item.quantityInKgDispatched} ${kgText}`,
              hyphenText,
            )}
          />
        </TableRow>
      ))}
    </TableBody>
  );
};
