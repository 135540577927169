/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Dialog, Divider, Typography } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import { StockUpdateModalHeader } from "./StockUpdateModalHeader";
import { StockUpdateModalDateAndTime } from "./StockUpdateModalDateAndTime";
import { StockUpdateModalDropdown } from "./StockUpdateModalDropdown";
import { StockModalTextfield } from "./StockModalTextfield";
import { StockUpdateModalActionButton } from "./StockUpdateModalActionButton";
import { updateStock } from "../../Services/StockService";
import { useDispatch } from "react-redux";
import { getStockDetailsByCategory } from "../../Services/StockService";
import { CategoryWiseStockData } from "../../Store/Reducers/data_menu";
import { getFormattedDate } from "../../Utils/common";

// ===========================================|| STOCK UPDATE MODAL ||============================================= //

export const StockUpdateModal = ({
  isOpenStockUpdateModal,
  selectedCategory,
  handleCloseStockUpdateModal,
  selectedDate,
  setSelectedDate,
  handleStockDateSelect,
  setSelectedItem,
  selectedItem,
  setSelectedBrand,
  selectedBrand,
  setSelectedCleanCategory,
  selectedCleanCategory,
  setSelectedUnit,
  selectedUnit,
  setStockQuantity,
  stockQuantity,
  setQuantityError,
  quantityError,
  setUpdatedItemData,
  updatedItemData,
  getFilteredItems,
  processingQuantity,
  setProcessingQuantity,
  isDisabled,
  setIsDisabled,
}) => {
  const {
    placeHolderTexts: {
      chooseItem,
      chooseBrand,
      chooseCleanCategory,
      chooseUnit,
      finishedKgText,
      finishedBagsText,
      processsingKgText,
      processingBagsText,
      dateInputFormat,
    },
    stockTabKeys: {
      brandIdsText,
      cleanCategoryIdsText,
      unitIdsText,
      itemIdText,
      itemNameText,
      idText,
      brandNameText,
      unitNameText,
      cleanCategoryNameText,
    },
    labels: { unKnownText },
    warnings: { onlyNumbersAreAllowed, stockAlreadyExistsText },
    keyLabels: { rawUpperCaseText, finishedText, processingText },
  } = uiTexts;

  const {
    itemList,
    brandList,
    cleanCategoryList,
    unitList,
    categoryWiseStockData,
  } = useSelector((state) => state.data_menu);
  const dispatch = useDispatch();

  const [filteredItems, setFilteredItems] = useState();
  const [filteredBrand, setFilteredBrand] = useState();
  const [filteredCleanCategory, setFilteredCleanCategory] = useState();
  const [filteredUnit, setFilteredUnit] = useState();
  const [quantity, setQuantity] = useState();
  const [duplicateMessage, setDuplicateMessage] = useState("");

  const isShowUpdateFields = selectedItem && !duplicateMessage;

  useEffect(() => {
    const quantity = itemList?.filter(
      (item) => item.itemId === selectedItem?.itemId && item.isRawItem,
    );

    setQuantity(quantity);
  }, [selectedItem]);

  useEffect(() => {
    const filteredItemsInfo = itemList?.filter(
      (item) => item.categoryName === selectedCategory?.categoryName,
    );

    setFilteredItems(filteredItemsInfo);
  }, [selectedCategory]);

  const filterItemsByIds = (
    selectedItem,
    filteredItems,
    idKey,
    list,
    setter,
  ) => {
    const selectedItemData = filteredItems?.find(
      (item) => item.itemId === selectedItem?.itemId,
    );

    if (selectedItemData?.[idKey]?.length > 0) {
      const correspondingItems = selectedItemData[idKey]
        ?.map((id) => list?.find((listItem) => listItem.id === id))
        .filter(Boolean);

      setter(correspondingItems);
    } else {
      setter([]);
    }
  };

  useEffect(() => {
    filterItemsByIds(
      selectedItem,
      filteredItems,
      brandIdsText,
      brandList,
      setFilteredBrand,
    );
    filterItemsByIds(
      selectedItem,
      filteredItems,
      cleanCategoryIdsText,
      cleanCategoryList,
      setFilteredCleanCategory,
    );
    filterItemsByIds(
      selectedItem,
      filteredItems,
      unitIdsText,
      unitList,
      setFilteredUnit,
    );
  }, [selectedItem, filteredItems]);

  const handleChangeItem = (e) => {
    setSelectedItem(e.target.value);
    setQuantityError({ finished: false, processing: false });
    setStockQuantity("");
    setDuplicateMessage("");
  };

  const handleFieldChange = (setValue, e) => {
    setValue(e.target.value);
    setDuplicateMessage("");
  };

  const handleChangeStockQuantity = (setter, label, e) => {
    const value = e.target.value;
    setter(value);

    if (!/^\d*$/.test(value)) {
      setQuantityError((prevState) => ({
        ...prevState,
        [label]: true,
      }));
    } else {
      setQuantityError((prevState) => ({
        ...prevState,
        [label]: false,
      }));
    }
  };

  const handleUpdateItem = async () => {
    setIsDisabled(true);

    const data = {
      date: selectedDate,
      itemName: selectedItem?.itemName,
      brandName: selectedBrand?.brandName,
      cleanCategoryName: selectedCleanCategory?.cleanCategoryName,
      unitListName: selectedUnit?.unitName,
      bags: quantity?.length === 0 ? Number(stockQuantity) : null,
      quantityInKg: quantity?.length > 0 ? Number(stockQuantity) : null,
      processingBags:
        quantity?.length === 0 ? Number(processingQuantity) : null,
      processingQuantityInKg:
        quantity?.length > 0 ? Number(processingQuantity) : null,
    };

    await updateStock(data).then(async ({ status }) => {
      if (status === 200) {
        await getStockDetailsByCategory(selectedCategory?.id).then(
          ({ data }) => {
            dispatch(CategoryWiseStockData(data));

            getFilteredItems(data);
          },
        );
      }
    });

    handleCloseStockUpdateModal();
  };

  const isUpdateButtonDisabled = () => {
    const isMissingSelection =
      !selectedItem ||
      (filteredBrand?.length && !selectedBrand) ||
      (filteredCleanCategory?.length && !selectedCleanCategory) ||
      (filteredUnit?.length && !selectedUnit);

    const hasErrors =
      !stockQuantity ||
      !processingQuantity ||
      duplicateMessage ||
      quantityError?.finished ||
      quantityError?.processing ||
      isDisabled;

    return isMissingSelection || hasErrors;
  };

  const updateButtonDisabled = isUpdateButtonDisabled();

  const checkForDuplicate = async () => {
    const formattedSelectedDate = getFormattedDate(
      selectedDate,
      dateInputFormat,
    );

    const isDuplicate = categoryWiseStockData?.some((existingItem) => {
      const isRawItem = selectedItem?.itemName?.includes(rawUpperCaseText);
      const existingIsRaw = existingItem.itemName.includes(rawUpperCaseText);
      const formattedExistingDate = getFormattedDate(
        existingItem.date,
        dateInputFormat,
      );

      const hasMatchingDate = formattedExistingDate === formattedSelectedDate;

      if (isRawItem) {
        return (
          existingIsRaw &&
          hasMatchingDate &&
          existingItem.itemName === selectedItem?.itemName
        );
      }

      return (
        hasMatchingDate &&
        existingItem.itemName === selectedItem?.itemName &&
        existingItem.brandName === selectedBrand?.brandName &&
        existingItem.cleanCategoryName ===
          (selectedCleanCategory?.cleanCategoryName || unKnownText) &&
        existingItem.unitListName === selectedUnit?.unitName
      );
    });

    setDuplicateMessage(isDuplicate && stockAlreadyExistsText);
  };

  useEffect(() => {
    checkForDuplicate();
  }, [
    selectedDate,
    selectedItem,
    selectedBrand,
    selectedCleanCategory,
    selectedUnit,
  ]);

  return (
    <Dialog
      open={isOpenStockUpdateModal}
      PaperProps={{
        sx: {
          borderRadius: "20px",
        },
      }}
    >
      <StockUpdateModalHeader
        title={selectedCategory?.categoryName}
        handleClose={handleCloseStockUpdateModal}
      />

      <Divider />

      <div className="list-container stock-update-modal-height">
        <StockUpdateModalDateAndTime
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          handleStockDateSelect={handleStockDateSelect}
          setDuplicateMessage={setDuplicateMessage}
        />

        <StockUpdateModalDropdown
          typographyText={chooseItem}
          value={selectedItem}
          handleChange={handleChangeItem}
          menuOptions={filteredItems}
          keyId={itemIdText}
          keyValue={itemNameText}
        />

        {filteredBrand?.length > 0 && (
          <StockUpdateModalDropdown
            typographyText={chooseBrand}
            value={selectedBrand}
            handleChange={(e) => handleFieldChange(setSelectedBrand, e)}
            menuOptions={filteredBrand}
            keyId={idText}
            keyValue={brandNameText}
          />
        )}

        {filteredUnit?.length > 0 && (
          <StockUpdateModalDropdown
            typographyText={chooseUnit}
            value={selectedUnit}
            handleChange={(e) => handleFieldChange(setSelectedUnit, e)}
            menuOptions={filteredUnit}
            keyId={idText}
            keyValue={unitNameText}
          />
        )}

        {filteredCleanCategory?.length > 0 && (
          <StockUpdateModalDropdown
            typographyText={chooseCleanCategory}
            value={selectedCleanCategory}
            handleChange={(e) => handleFieldChange(setSelectedCleanCategory, e)}
            menuOptions={filteredCleanCategory}
            keyId={idText}
            keyValue={cleanCategoryNameText}
          />
        )}

        {isShowUpdateFields && (
          <>
            <StockModalTextfield
              typographyText={
                quantity?.length > 0 ? finishedKgText : finishedBagsText
              }
              value={stockQuantity}
              handleChange={(e) =>
                handleChangeStockQuantity(setStockQuantity, finishedText, e)
              }
              errorText={quantityError?.finished ? onlyNumbersAreAllowed : ""}
              fieldStyle={{ width: "260px" }}
            />

            <StockModalTextfield
              typographyText={
                quantity?.length > 0 ? processsingKgText : processingBagsText
              }
              value={processingQuantity}
              handleChange={(e) =>
                handleChangeStockQuantity(
                  setProcessingQuantity,
                  processingText,
                  e,
                )
              }
              errorText={quantityError?.processing ? onlyNumbersAreAllowed : ""}
              fieldStyle={{ width: "260px" }}
            />
          </>
        )}

        {duplicateMessage && (
          <Typography className="stock-update-error-text">
            {duplicateMessage}
          </Typography>
        )}

        <StockUpdateModalActionButton
          handleCloseStockUpdateModal={handleCloseStockUpdateModal}
          updateButtonDisabled={updateButtonDisabled}
          handleUpdateItem={handleUpdateItem}
        />
      </div>
    </Dialog>
  );
};
