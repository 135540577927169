import React from "react";
import { Table, TableContainer } from "@mui/material";
import { CustomerOrderListHeader } from "./CustomerOrderListHeader";
import { CustomerOrderListDetails } from "./CustomerOrderListDetails";
import { customerOrderTableHeaders } from "../../../Utils/globalUtils";

// ===================================================|| CUSTOMER ORDER HEADER ||=================================================== //

export const CustomerOrderTable = ({
  setSelectedCustomer,
  handleChange,
  customerDetailsSelect,
  orderItems,
  setOrderItems,
  isTomorrowInTimeLine,
  isTimelineModal,
}) => {
  return (
    <TableContainer className="customer-order-details-header list-container order-details-page-height table-container-style">
      <Table stickyHeader>
        <CustomerOrderListHeader headers={customerOrderTableHeaders} />

        <CustomerOrderListDetails
          setSelectedCustomer={setSelectedCustomer}
          handleChange={handleChange}
          customerDetailsSelect={customerDetailsSelect}
          orderItems={orderItems}
          setOrderItems={setOrderItems}
          isTomorrowInTimeLine={isTomorrowInTimeLine}
          isTimelineModal={isTimelineModal}
        />
      </Table>
    </TableContainer>
  );
};

export default CustomerOrderTable;
