import React from "react";
import { Grid, Typography, Stack } from "@mui/material";
import { uiTexts } from "../../../Utils/uiTexts";

// ===================================================|| CUSTOMER ORDER HEADER DETAILS ITEM ||=================================================== //

export const DateHeaderItem = ({ value, label, icon: Icon }) => {
  const {
    labels: { stringText },
  } = uiTexts;

  return (
    <Grid
      container
      className="customer-order-header-details-style customer-order-header-details-style-margin center-alignment"
    >
      <Grid item xs={3}>
        <Stack direction="row" className="center-alignment" spacing={1}>
          {typeof Icon === stringText ? (
            <img src={Icon} alt="icon" className="header-arrow-icons" />
          ) : (
            <Icon className="date-icon-color header-arrow-icons" />
          )}
          <Typography className="customer-header-list-text">{label}</Typography>
        </Stack>
      </Grid>

      <Grid item xs={6}>
        <Typography className="customer-header-details-list-text">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};
