import React from "react";
import { TableHead, TableCell, Typography, TableRow } from "@mui/material";

// ===================================================|| CUSTOMER ORDER HEADER ||=================================================== //

export const CustomerOrderListHeader = ({ headers }) => {
  return (
    <TableHead>
      <TableRow>
        {headers?.map((header, index) => (
          <TableCell key={index} className="customer-list-details-divider">
            <Typography className="orders-text-header">
              {header.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
