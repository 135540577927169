import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Button, ListItem, Typography, Stack } from "@mui/material";
import { EditBillModal } from "../EditBillsModal/EditBillModal";
import BillIcon from "../../Image/billIcon.png";
import { getOrderItemTransactionById } from "../../Services/OrderService";
import { formatDate, formatAmount } from "../../Utils/common";
import cashIcon from "../../Image/cashIcon.png";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { uiTexts } from "../../Utils/uiTexts";
import "../../CustomersTab/CustomerPage.css";
import "../../EditBills/editbills.css";

// ============================================|| EDIT BILLS RHS CONTENT ||============================================ //

export const EditBillsRhsContent = ({
  selectedCustomer,
  setSelectedCustomer,
}) => {
  const {
    labels: { rsText },
    keyLabels: { fareAmountText, netFareAmountText },
  } = uiTexts;

  const { billsData } = useSelector((state) => state.data_menu);

  const [selectedBill, setSelectedBill] = useState("");
  const [orderItems, setOrderItems] = useState([]);
  const [billClick, setBillClick] = useState(false);
  const [customerDetailsSelect, setCustomerDetailsSelect] = useState([]);
  const [partyGroups, setPartyGroups] = useState({ data: [], status: null });
  const [selectedBillData, setSelectedBillData] = useState();
  const [selectedBillBag, setSelectedBillBag] = useState();
  const [selectedBillId, setSelectedBillId] = useState();

  const calculateTotalByKey = (array, key) => {
    return array?.reduce((total, item) => total + (item[key] || 0), 0);
  };

  const handleBillSelection = async (billNo, billId) => {
    const bill = billsData?.find(
      (bill) => bill.billNo === billNo && bill.id === billId,
    );

    if (bill) {
      setSelectedBill(billNo);
      setSelectedBillId(billId);
      setSelectedBillData(bill);

      const orderItems = [];
      for (const id of bill?.orderItemTransactionIds) {
        const orderItemData = await getOrderItemTransactionById(id);
        orderItems.push(orderItemData?.data);
      }

      const fareAmount = calculateTotalByKey(bill?.details, fareAmountText);
      const netFareAmount = calculateTotalByKey(
        bill?.details,
        netFareAmountText,
      );
      const getTaxPercentage = (gstAmount) =>
        gstAmount !== null && gstAmount !== 0
          ? `${Math.round(gstAmount * 100)}%`
          : null;

      const newDetails = orderItems?.map((orderItem) => {
        const detailFromBill = bill?.details.find(
          (detail) => detail.orderItemTransactionId === orderItem.id,
        );

        return {
          ...orderItem,
          rate: detailFromBill?.rate || 0,
          bag: detailFromBill?.bag || 0,
          orderDate: detailFromBill ? orderItem.orderDate : null,
          taxAmount: detailFromBill?.taxAmount || 0,
          netAmount: detailFromBill?.netAmount || 0,
          fareAmount: detailFromBill?.fareAmount || 0,
          netFareAmount: detailFromBill?.netFareAmount || 0,
          deliveryRate: detailFromBill?.deliveryRate || 0,
          gstPercentageAmount: detailFromBill?.gstPercentageAmount ?? null,
          taxPercentage: getTaxPercentage(detailFromBill?.gstPercentageAmount),
        };
      });

      if (newDetails.length > 0) {
        setSelectedBillBag(newDetails[0]?.bag);
      }

      setSelectedBillData((prevData) => ({
        ...prevData,
        details: newDetails,
        fareAmount,
        netFareAmount,
      }));

      setBillClick(true);
    }
  };

  const IconText = ({ iconSrc, text }) => (
    <Stack direction="row">
      {iconSrc && (
        <img src={iconSrc} className="payment-icon-style" alt="Icon" />
      )}
      <Typography className="bill-details-list-style">{text}</Typography>
    </Stack>
  );

  const getBillDetails = (billNo) => {
    const matchBill = billsData?.find((bills) => bills.billNo === billNo);
    return matchBill ? matchBill : null;
  };

  return (
    <>
      <Grid item xs={6}>
        <div className="list-container pending-bill-rhs-list-height">
          {selectedCustomer?.billNumbers?.map((bill, billIndex) => (
            <React.Fragment key={billIndex}>
              <Button
                fullWidth
                className={`orders-payment-content--button ${
                  selectedBill === bill.billNo &&
                  "orders-payment-content--button--selected"
                }`}
                onClick={() => handleBillSelection(bill.billNo, bill.billId)}
              >
                <ListItem>
                  <Grid container>
                    <Grid item xs={10}>
                      <IconText
                        iconSrc={BillIcon}
                        text={`${bill.billNo} ( ${formatDate(
                          getBillDetails(bill.billNo)?.date,
                        )} )`}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <IconText
                        iconSrc={cashIcon}
                        text={
                          <>
                            <CurrencyRupeeIcon className="payment-currency-icon custom-currency-icon edit-bill-rhs-list-margin" />
                            {`${rsText} ${formatAmount(
                              getBillDetails(bill.billNo)?.totalNetAmount,
                              2,
                            )} `}
                          </>
                        }
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              </Button>
            </React.Fragment>
          ))}
        </div>
      </Grid>

      {billClick && (
        <EditBillModal
          customerDetailsSelect={customerDetailsSelect}
          setCustomerDetailsSelect={setCustomerDetailsSelect}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          billClick={billClick}
          setBillClick={setBillClick}
          orderItems={orderItems}
          setOrderItems={setOrderItems}
          partyGroups={partyGroups}
          setPartyGroups={setPartyGroups}
          selectedBill={selectedBill}
          setSelectedBillData={setSelectedBillData}
          selectedBillData={selectedBillData}
          selectedBillBag={selectedBillBag}
          setSelectedBillBag={setSelectedBillBag}
          setSelectedBillId={setSelectedBillId}
          selectedBillId={selectedBillId}
        />
      )}
    </>
  );
};
