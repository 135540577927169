/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { uiTexts } from "../../../Utils/uiTexts";
import { KpiHeaderDetails } from "../KpiHeaderDetails";
import { KpiTable } from "./KpiTable";

// ==========================================|| KPI TABLE DETAILS ||=========================================== //

export const KpiDetailsTable = ({
  data,
  headerText,
  column1Text,
  column1Key,
  column2Key,
  isSubheader,
}) => {
  const {
    keyLabels: { numberText },
  } = uiTexts;

  const [negativeValuesCount, setNegativeValuesCount] = useState(0);

  useEffect(() => {
    let count = 0;
    data?.forEach((item) => {
      const value = item[column2Key];
      if (typeof value === numberText && value < 0) {
        count++;
      }
    });
    setNegativeValuesCount(count);
  }, [data, column2Key]);

  return (
    <>
      <KpiHeaderDetails
        headerText={headerText}
        subHeaderText={column1Text}
        isSubheader={isSubheader}
      />

      <KpiTable
        data={data}
        negativeValuesCount={negativeValuesCount}
        column1Key={column1Key}
        column2Key={column2Key}
        tableHeaderText={column1Text}
      />
    </>
  );
};
