import React from "react";
import { Button, Grid } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import "../../OrdersTab/OrderPage.css";

// ===================================|| STOCK UPDATE MODAL ACTION BUTTON ||================================= //

export const StockUpdateModalActionButton = ({
  handleCloseStockUpdateModal,
  updateButtonDisabled,
  handleUpdateItem,
  setIsDisabled,
}) => {
  const {
    buttonText: { updateText, cancelText },
  } = uiTexts;

  const ActionButton = ({ onClick, disabled, text, buttonStyle }) => (
    <Button
      className={buttonStyle}
      onClick={() => onClick()}
      disabled={disabled}
    >
      {text}
    </Button>
  );

  return (
    <Grid
      container
      spacing={5}
      className="order-details-modal-table"
      justifyContent="center"
    >
      <Grid item>
        <ActionButton
          onClick={handleCloseStockUpdateModal}
          text={cancelText}
          buttonStyle="bill-modal-button bill-modal-cancel-button"
        />
      </Grid>

      <Grid item>
        <ActionButton
          onClick={handleUpdateItem}
          text={updateText}
          buttonStyle={`bill-modal-button ${
            updateButtonDisabled
              ? "bill-modal-update-button-disabled-bg-color"
              : "bill-modal-update-button-bg-color"
          }`}
          disabled={updateButtonDisabled}
        />
      </Grid>
    </Grid>
  );
};
