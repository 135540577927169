import React from "react";
import { TableBody, TableCell, TableRow, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { uiTexts } from "../../../Utils/uiTexts";
import {
  formatDate,
  formatAmount,
  getNetAmount,
  extractNumericValue,
  convertToFloat,
} from "../../../Utils/common";
import { OrderSelectedTableSelectField } from "./OrderSelectedTableSelectField";
import "../../OrderPage.css";

// ==============================================|| ORDER DETAILS TABLE ITEM ||============================================== //

export const OrderSelectedDetailsTableItem = ({
  dummyOrder,
  setDummyOrder,
  filteredOrders,
  errorState,
  setErrorState,
  isEditBillModal,
  selectedBillData,
  setSelectedBillData,
  selectedBillBag,
}) => {
  const {
    warnings: { inValidValueText },
    labels: {
      unKnownText,
      pendingBagText,
      ratetext,
      taxAmountText,
      bagLowerCaseText,
      deliveryRateLowerCaseText,
    },
  } = uiTexts;

  const { salesParametersData, taxList } = useSelector(
    (state) => state.data_menu,
  );

  const handleInputChange = (index, field, value) => {
    const updatedOrder = [...dummyOrder];
    const updatedErrorState = [...errorState];

    // Validate numeric input
    if (!/^\d*\.?\d*$/.test(value) && value !== "") {
      updatedErrorState[index] = { ...updatedErrorState[index], [field]: true };
      setErrorState(updatedErrorState);
      return;
    }

    let newValue = value;

    if (value === "") {
      newValue = 0;
    } else if (field === pendingBagText || field === bagLowerCaseText) {
      newValue = Number(value);
    }

    let error = false;

    const currentOrder = updatedOrder[index];
    const originalRate = currentOrder?.rate;
    const originalDeliveryRate = currentOrder?.deliveryRate;
    const originalPendingBag = Number(filteredOrders[index]?.pendingBag);
    const limitValue = Number(salesParametersData[0]?.limitValue);

    switch (field) {
      case pendingBagText:
        error = newValue > originalPendingBag + limitValue || newValue <= 0;
        currentOrder.pendingBag = newValue;
        break;

      case ratetext:
        error = newValue <= 0;
        currentOrder.rate = newValue;
        currentOrder.taxAmount = 0;
        currentOrder.taxPercentage = null;
        currentOrder.taxPercentageAmount = null;
        break;

      case deliveryRateLowerCaseText: {
        error = newValue >= originalRate;

        if (!error) {
          const deliveryRateDifference = convertToFloat(
            newValue - originalDeliveryRate,
          );
          const updatedRate = originalRate - deliveryRateDifference;

          currentOrder.rate = Math.max(0, updatedRate);
          error = currentOrder?.rate === 0;
        }
        break;
      }

      case taxAmountText:
        error = newValue < 0;
        break;

      case bagLowerCaseText:
        const updatedSelectedBillData = { ...selectedBillData };
        const targetDetail = updatedSelectedBillData?.details?.find(
          (detail) => detail.id === currentOrder?.id,
        );
        if (targetDetail) {
          targetDetail.bag = newValue;
          setSelectedBillData(updatedSelectedBillData);
        }

        const allowedLimit =
          filteredOrders[index]?.pendingBag === 0
            ? Number(selectedBillBag) + limitValue
            : originalPendingBag + Number(selectedBillBag) + limitValue;

        error = newValue > allowedLimit || newValue <= 0;
        break;

      default:
        break;
    }

    updatedErrorState[index] = { ...updatedErrorState[index], [field]: error };

    if (!error) {
      currentOrder[field] = newValue;
    }

    // Update state only once
    setDummyOrder(updatedOrder);
    setErrorState(updatedErrorState);
  };

  const handleTax = (event, index) => {
    setDummyOrder((prevOrders) => {
      const updatedOrder = [...prevOrders];
      const currentOrder = { ...updatedOrder[index] };

      currentOrder.taxPercentage = event.target.value;

      const numericTaxRate = parseFloat(currentOrder?.taxPercentage) || 0;
      const amount = getNetAmount(currentOrder, false, isEditBillModal);
      const taxAmount = (amount * numericTaxRate) / 100;

      currentOrder.taxAmount = taxAmount;
      currentOrder.gstPercentageAmount = numericTaxRate / 100;

      updatedOrder[index] = currentOrder;

      return updatedOrder;
    });
  };

  const calculateQuantity = (order, isEditBillModal) => {
    if (order?.quantityInKg !== null) return order?.quantityInKg;

    const multiplier = extractNumericValue(order?.unitListName);
    return isEditBillModal
      ? order?.bag * multiplier
      : order?.pendingBag * multiplier;
  };

  const renderTextField = (index, field, value, isError, taxPercent) => (
    <TextField
      value={value}
      size="small"
      onChange={(e) => {
        if (taxPercent && taxPercent !== null) return;

        const inputValue = e.target.value.replace(/^0+/, "");
        handleInputChange(index, field, inputValue || 0);
      }}
      error={isError}
      helperText={isError ? inValidValueText : ""}
    />
  );

  return (
    <TableBody>
      {dummyOrder?.map((order, index) => (
        <TableRow key={index}>
          <TableCell className="bill-modal-font">{index + 1}</TableCell>

          <TableCell className="bill-modal-font">
            {formatDate(order.orderDate)}
          </TableCell>

          <TableCell className="bill-modal-font">
            {`${order.itemName}
            ${order.unitListName !== unKnownText ? order.unitListName : ""}
            ${
              order.cleanCategoryName !== unKnownText
                ? order.cleanCategoryName
                : ""
            }`}
          </TableCell>

          <TableCell className="bill-modal-font">
            {order.quantityInKg === null
              ? renderTextField(
                  index,
                  isEditBillModal ? bagLowerCaseText : pendingBagText,
                  isEditBillModal ? order.bag : order.pendingBag,
                  isEditBillModal
                    ? errorState[index]?.bag
                    : errorState[index]?.pendingBag,
                )
              : order.quantityInKg}
          </TableCell>

          <TableCell className="bill-modal-font">
            {calculateQuantity(order, isEditBillModal)}
          </TableCell>

          <TableCell className="bill-modal-font">
            {renderTextField(
              index,
              ratetext,
              order.rate,
              errorState[index]?.rate,
            )}
          </TableCell>

          <TableCell className="bill-modal-font">
            {renderTextField(
              index,
              deliveryRateLowerCaseText,
              order.deliveryRate || 0,
              errorState[index]?.deliveryRate,
            )}
          </TableCell>

          <TableCell className="bill-modal-font">
            <OrderSelectedTableSelectField
              index={index}
              taxValue={order.taxPercentage}
              handleTax={handleTax}
              taxList={taxList}
            />
          </TableCell>

          <TableCell className="bill-modal-font">
            {renderTextField(
              index,
              taxAmountText,
              order.taxAmount || 0,
              errorState[index]?.taxAmount,
              order.taxPercentage,
            )}
          </TableCell>

          <TableCell>
            {formatAmount(getNetAmount(order, true, isEditBillModal), 2)}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
