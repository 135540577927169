import React, { useState, useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { uiTexts } from "../Utils/uiTexts";
import { MINUTE_MS } from "../Utils/common";
import useRetrieveFunctions from "../CustomHooks/useRetrieveFunctions";
import { SearchField } from "../CommonComp/SearchField";
import { OrderPageDetails } from "./OrderPageDetails";
import { getFormattedDate } from "../Utils/common";
import { Loader } from "../Loader/Loader";
import dayjs from "dayjs";
import "./OrderPage.css";

// ===================================================|| ORDERS PAGE ||=================================================== //

export const OrderPage = () => {
  const {
    placeHolderTexts: { searchCustomerText, dateInputFormat },
    warnings: { customerNotFoundText },
  } = uiTexts;

  const { customerPendingOrders, orderDataByDate } = useSelector(
    (state) => state.data_menu,
  );
  const { customerPendingOrderStatusCode, customerStatusCode } = useSelector(
    (state) => state.status_menu,
  );

  const {
    retrieveCustomerPendingOrders,
    retrieveSalesParametersData,
    retrieveCustomerData,
    retrieveOrders,
    retrieveAllOrderItemTransactions,
  } = useRetrieveFunctions();

  const [searchList, setSearchList] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedDate, setSelectedDate] = useState([]);
  const [orderLhsHeader, setOrderLhsHeader] = useState(true);
  const [uniqueDatesWithCount, setUniqueDatesWithCount] = useState([]);

  // Filtering Customers
  const filteredCustomers = customerPendingOrders?.filter((customer) =>
    customer.partyName.toLowerCase().includes(searchList.toLowerCase()),
  );

  useEffect(() => {
    retrieveAllOrderItemTransactions();

    const interval = setInterval(() => {
      retrieveCustomerPendingOrders();
      retrieveOrders();
    }, MINUTE_MS);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    retrieveSalesParametersData();
    retrieveCustomerData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const dateCountMap = {};

    orderDataByDate?.forEach((item) => {
      const formattedDate = getFormattedDate(item.orderDate, dateInputFormat);
      dateCountMap[formattedDate] = (dateCountMap[formattedDate] || 0) + 1;
    });

    const uniqueDatesArray = Object.entries(dateCountMap)
      .map(([date, count]) => ({ date, count }))
      .sort(
        (a, b) =>
          dayjs(b.date, dateInputFormat) - dayjs(a.date, dateInputFormat),
      );

    setUniqueDatesWithCount(uniqueDatesArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDataByDate]);

  const isEmptyData =
    customerPendingOrders?.length !== 0 &&
    (filteredCustomers?.length === 0 ||
      filteredCustomers?.length === undefined);

  const isOrderTabLoading =
    !customerPendingOrderStatusCode ||
    !customerStatusCode ||
    (!orderLhsHeader && orderDataByDate?.length === 0);

  return (
    <>
      {isOrderTabLoading ? (
        <Loader />
      ) : (
        <Stack>
          <div className="customer-search-container-order customer-search-container-order-margin">
            <SearchField
              searchList={searchList}
              setSearchList={setSearchList}
              searchLabel={searchCustomerText}
              setSelectedData={setSelectedCustomer}
            />
          </div>

          {isEmptyData ? (
            <Typography className="order-customer-not-found">
              {customerNotFoundText}
            </Typography>
          ) : (
            <OrderPageDetails
              filteredCustomers={filteredCustomers}
              setSelectedCustomer={setSelectedCustomer}
              selectedCustomer={selectedCustomer}
              orderLhsHeader={orderLhsHeader}
              setOrderLhsHeader={setOrderLhsHeader}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              uniqueDatesWithCount={uniqueDatesWithCount}
            />
          )}
        </Stack>
      )}
    </>
  );
};
