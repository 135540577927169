import React from "react";
import { Typography } from "@mui/material";

// ==========================================|| KPI DETAILS HEADER||=========================================== //

export const KpiHeaderDetails = ({
  headerText,
  subHeaderText,
  isSubheader,
}) => {
  return (
    <Typography className="details-header">
      {headerText}

      {isSubheader && (
        <Typography component="span" className="details-sub-header">
          ( {subHeaderText} )
        </Typography>
      )}
    </Typography>
  );
};
