import React from "react";
import { Typography, Grid } from "@mui/material";

// ==========================================|| HEADER GRID ||========================================== //

export const HeaderGrid = ({ text, xs, count }) => {
  return (
    <Grid item xs={xs}>
      <Typography className="customer-list-header-text">
        {text} {count && <span>({count})</span>}
      </Typography>
    </Grid>
  );
};
