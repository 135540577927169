import React from "react";
import { TableRow, TableCell, TableHead } from "@mui/material";
import { timelineDispatchDetailsTableHeader } from "../../../Utils/globalUtils";

// ======================================|| DISPATCH TABLE HEADER ||============================================== //

export const DispatchDetailsTableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        {timelineDispatchDetailsTableHeader?.map((header, index) => (
          <TableCell key={index} className="gantt-item-table-padding bold-text">
            {header}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
