import React from "react";
import { useSelector } from "react-redux";
import { uiTexts } from "../../Utils/uiTexts";
import { KpiDetailsTable } from "./KpiTable/KpiDetailsTable";

// ==========================================|| KPI RHS ||=========================================== //

export const KpiRhs = ({ selectedKpi }) => {
  const {
    kpiCardsLabels: {
      transportWiseDispatchAverage,
      orderToBillAverageText,
      billToPaymentAverageText,
      partyNameText,
      orderToBillText,
      transportNameLowerCaseText,
      billToDispatchText,
      billToPaymentText,
    },
    labels: { transportNameText, customerText },
  } = uiTexts;

  const {
    orderToBillDelayDetails,
    billToDispatchDelayDetails,
    billToPaymentDelayDetails,
  } = useSelector((state) => state.kpi_menu);

  //Modify negative values to zero only for payment delay
  const updatedBillToPaymentDelayDetails =
    billToPaymentDelayDetails?.partyWise?.map((item) => ({
      ...item,
      billToPayment: Math.max(item.billToPayment, 0),
    })) || [];

  return (
    <>
      {selectedKpi?.index === 0 && (
        <KpiDetailsTable
          data={orderToBillDelayDetails?.partyWise}
          headerText={orderToBillAverageText}
          column1Text={customerText}
          column1Key={partyNameText}
          column2Key={orderToBillText}
          isSubheader={true}
        />
      )}

      {selectedKpi?.index === 1 && (
        <KpiDetailsTable
          data={billToDispatchDelayDetails?.transportWise}
          headerText={transportWiseDispatchAverage}
          column1Text={transportNameText}
          column1Key={transportNameLowerCaseText}
          column2Key={billToDispatchText}
          isSubheader={false}
        />
      )}

      {selectedKpi?.index === 2 && (
        <KpiDetailsTable
          data={updatedBillToPaymentDelayDetails}
          headerText={billToPaymentAverageText}
          column1Text={customerText}
          column1Key={partyNameText}
          column2Key={billToPaymentText}
          isSubheader={true}
        />
      )}
    </>
  );
};
