import React from "react";
import {
  TableCell,
  TableHead,
  TableRow,
  Stack,
  Typography,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import cashIcon from "../../../Image/cashIcon.png";
import { uiTexts } from "../../../Utils/uiTexts";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

// ===================================================|| MODAL PAYMENT DETAILS ||=================================================== //

export const BillModalPaymentDetailsHeader = ({ filteredBillDetails }) => {
  const {
    labels: { dateText, amountUpperText },
  } = uiTexts;
  return (
    <TableHead>
      <TableRow className="payment-details-modal-header-sticky">
        <TableCell className="payment-details-modal-info-width">
          <Stack direction="row">
            <CalendarTodayIcon className="payment-icon-style payment-modal-icon-color" />

            <Typography className="modal-table-header-style">
              {dateText}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell>
          <Stack direction="row" alignItems="center">
            <img
              src={cashIcon}
              className="payment-icon-style payment-modal-icon-color"
              alt="logo"
            />

            <Typography className="modal-table-header-style">
              {amountUpperText}
              (
              <CurrencyRupeeIcon className="payment-currency-icon custom-currency-icon" />
              )
            </Typography>
          </Stack>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
