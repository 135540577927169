import React from "react";
import { Button, Grid, ListItem, Typography } from "@mui/material";
import storeIcon from "../../Image/storeIcon.png";
import { capitalizeFirstLowercaseRest } from "../../Utils/common";
import { uiTexts } from "../../Utils/uiTexts";
import "../OrderPage.css";

// ==============================================|| ORDER LIST DETAILS ||============================================== //

export const OrderListDetails = ({
  selectedCustomer,
  handleCustomerSelect,
  filteredCustomers,
}) => {
  const {
    separators: { hyphenText },
  } = uiTexts;

  const RenderLabel = ({ xsValue, children }) => {
    return (
      <Grid item xs={xsValue}>
        <Typography className="customer-details-list-text">
          {children}
        </Typography>
      </Grid>
    );
  };

  return (
    <>
      {filteredCustomers?.map((orders) => {
        if (orders.totalPendingOrders === 0) return null;

        const isSelected =
          selectedCustomer && orders.partyId === selectedCustomer.partyId;

        return (
          <Button
            key={orders.partyId}
            className={`orders-payment-content--button ${
              isSelected ? "orders-payment-content--button--selected" : ""
            }`}
            onClick={() => handleCustomerSelect(orders)}
          >
            <ListItem>
              <Grid container>
                <RenderLabel xsValue={9}>
                  <img src={storeIcon} className="storeIcon-style" alt="logo" />
                  {orders.partyName}
                  {orders.partyArea &&
                    ` ${hyphenText} ${capitalizeFirstLowercaseRest(
                      orders.partyArea,
                    )}`}
                </RenderLabel>

                <RenderLabel xsValue={3}>
                  {orders.totalPendingOrders}
                </RenderLabel>
              </Grid>
            </ListItem>
          </Button>
        );
      })}
    </>
  );
};
