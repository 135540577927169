import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Loader } from "../Loader/Loader";
import { Header } from "../Header/Header";
import useRetrieveFunctions from "../CustomHooks/useRetrieveFunctions";
import { MINUTE_MS } from "../Utils/common";
import { Footer } from "../Footer/Footer";
import { PaymentPage } from "./PaymentPage";
import "./PaymentPage.css";

// ===================================================|| PAYMENT PAGE ||=================================================== //

function Payment() {
  const { customerPendingStatusCode, customerStatusCode } = useSelector(
    (state) => state.status_menu,
  );

  const { retrieveCustomerPendingPayments, retrieveCustomerData } =
    useRetrieveFunctions();

  useEffect(() => {
    const interval = setInterval(() => {
      retrieveCustomerPendingPayments();
    }, MINUTE_MS);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    retrieveCustomerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isPaymentLoading = !customerPendingStatusCode || !customerStatusCode;

  return (
    <>
      <Header />

      {isPaymentLoading ? <Loader /> : <PaymentPage />}

      <Footer />
    </>
  );
}
export default Payment;
