import React from "react";
import { Paper, Typography, Grid } from "@mui/material";
import { uiTexts } from "../../Utils/uiTexts";
import { formatAmount } from "../../Utils/common";
import { useSelector } from "react-redux";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import cashIcon from "../../Image/cashIcon.png";

// ==============================================|| SALES SUMMARY ||============================================= //

export const SalesSummary = () => {
  const {
    chartLabels: {
      salesSummaryText,
      totalOrdersText,
      totalAmountText,
      totalItemOrderText,
    },
    labels: { pendingOrdersText, pendingAmountText, rsText },
  } = uiTexts;

  const { salesSummaryDetails } = useSelector((state) => state.kpi_menu);

  const AnalysisCard = ({ title, value, icon, boxStyle, gridStyle }) => (
    <Grid
      item
      className={`analysis-overall-box analysis-cards-div-style ${gridStyle}`}
    >
      <div className="analysis-overall-box">
        <div className={boxStyle}>{icon}</div>

        <Typography className="analysis-card-title">{title}</Typography>

        <Typography className="analysis-card-value">{value}</Typography>
      </div>
    </Grid>
  );

  const cardData = [
    {
      title: totalOrdersText,
      value: salesSummaryDetails?.totalOrders || 0,
      icon: <ShoppingCartIcon className="sales-summary-icon" />,
      boxStyle: "analysis-card-box-bg1",
      gridStyle: "analysis-cards-bg1",
    },
    {
      title: totalItemOrderText,
      value: salesSummaryDetails?.totalItemOrders || 0,
      icon: <ShoppingBagIcon className="sales-summary-icon" />,
      boxStyle: "analysis-card-box-bg2",
      gridStyle: "analysis-cards-bg2",
    },
    {
      title: totalAmountText,
      value: `${rsText} ${
        formatAmount(salesSummaryDetails?.totalAmount, 0) || 0
      }`,
      icon: (
        <img src={cashIcon} className="sales-summary-cash-icon" alt="logo" />
      ),
      boxStyle: "analysis-card-box-bg3",
      gridStyle: "analysis-cards-bg3",
    },
    {
      title: pendingOrdersText,
      value: salesSummaryDetails?.totalPendingOrders || 0,
      icon: <ShoppingCartIcon className="sales-summary-icon" />,
      boxStyle: "analysis-card-box-bg4",
      gridStyle: "analysis-cards-bg4",
    },
    {
      title: pendingAmountText,
      value: `${rsText} ${
        formatAmount(salesSummaryDetails?.totalPendingAmount, 0) || 0
      }`,
      icon: (
        <img src={cashIcon} className="sales-summary-cash-icon" alt="logo" />
      ),
      boxStyle: "analysis-card-box-bg4",
      gridStyle: "analysis-cards-bg4",
    },
  ];

  return (
    <Paper className="sales-summary-background">
      <Typography className="chart-header-text ">{salesSummaryText}</Typography>

      <Grid container className="analysis-flex-row">
        {cardData?.map((card, index) => (
          <AnalysisCard
            key={index}
            title={card.title}
            value={card.value}
            icon={card.icon}
            boxStyle={`analysis-card-box ${card.boxStyle}`}
            gridStyle={`analysis-cards-div-style ${card.gridStyle}`}
          />
        ))}
      </Grid>
    </Paper>
  );
};
